// src/components/ToggleButton.js
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useUpdateAdvisorLiveRateMutation,
  useUpdateAdvisorLiveStatusMutation,
  useUpdateAdvisorStatusMutation,
  useUpdateRushStatusMutation,
} from "../app/api/advisor";
import { getUserData } from "../utils/helper/getUserId";
import Loader from "./Loader";
import useLogout from "../hooks/useLogout";
import { HiOutlineDotsVertical } from "react-icons/hi";
import {
  setErrorModalMessage,
  setIsErrorModalOpen,
} from "../features/modal/modalSlice";
import toast, { Toaster } from "react-hot-toast";
import { setGetadvisorSelectedRate } from "../features/advisor/advisorSlice";

const AdvisorStatus = () => {
  const dispatch = useDispatch();
  const { handleLogout } = useLogout();
  const {
    advisorLiveStatus,
    advisorRushStatus,
    advisorStatus,
    getAdvisorLiveRate,
    getadvisorSelectedRate,
  } = useSelector((state) => state.advisor);
  const { advisorLoginResponse } = useSelector((state) => state.login);
  const [updateAdvisorLiveStatus, { error }] =
    useUpdateAdvisorLiveStatusMutation();
  const [updateRushStatus, { error: rushError }] =
    useUpdateRushStatusMutation();
  const [updateAdvisorStatus, { error: statusError }] =
    useUpdateAdvisorStatusMutation();
  const [updateAdvisorLiveRate, { error: liveRateError }] =
    useUpdateAdvisorLiveRateMutation();
  const user = getUserData(advisorLoginResponse);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const { id = "" } = user || {};
  if (error || rushError || statusError || liveRateError) {
    if (
      error?.status === 401 ||
      rushError?.status === 401 ||
      statusError?.status === 401 ||
      liveRateError?.status === 401
    ) {
      handleLogout();
    }
  }

  const toggle = async () => {
    try {
      setIsStatusLoading(true);
      const formAdvisorStatusUpdate = new FormData();
      formAdvisorStatusUpdate.append("id", id);
      formAdvisorStatusUpdate.append("live_status", advisorLiveStatus ? 0 : 1);
      formAdvisorStatusUpdate.append("device_type", "web");
      formAdvisorStatusUpdate.append("user_id", id);
      formAdvisorStatusUpdate.append("user_type", "advisor");
      const result = await updateAdvisorLiveStatus(
        formAdvisorStatusUpdate
      ).unwrap();

      if (result?.result == "1") {
        setIsStatusLoading(false);
        toast.success("Live Status Updated!");
      } else if (result?.result == "0") {
        setIsStatusLoading(false);
        dispatch(setErrorModalMessage(result?.message));
        dispatch(setIsErrorModalOpen(true));
      } else if (result?.result == "-1") {
        setIsStatusLoading(false);
        dispatch(setErrorModalMessage(result?.message));
        dispatch(setIsErrorModalOpen(true));
      }
    } catch (err) {
      if (err?.status === 401) {
        setIsStatusLoading(false);
        handleLogout();
      }
    }
  };
  const toggleRush = async () => {
    try {
      setIsStatusLoading(true);
      const formAdvisorStatusUpdate = new FormData();
      formAdvisorStatusUpdate.append("id", id);
      formAdvisorStatusUpdate.append("status", advisorRushStatus ? 0 : 1);
      formAdvisorStatusUpdate.append("device_type", "web");
      formAdvisorStatusUpdate.append("user_id", id);
      formAdvisorStatusUpdate.append("user_type", "advisor");
      const result = await updateRushStatus(formAdvisorStatusUpdate).unwrap();
      if (result?.result == "1") {
        setIsStatusLoading(false);
        toast.success("Rush Status Updated!");
      } else if (result?.result == "0") {
        setIsStatusLoading(false);
        dispatch(setErrorModalMessage(result?.message));
        dispatch(setIsErrorModalOpen(true));
      } else if (result?.result == "-1") {
        setIsStatusLoading(false);
        dispatch(setErrorModalMessage(result?.message));
        dispatch(setIsErrorModalOpen(true));
      }
    } catch (err) {
      if (err?.status === 401) {
        setIsStatusLoading(false);
        handleLogout();
      }
    }
  };
  const toggleStatus = async () => {
    try {
      setIsStatusLoading(true);
      const formAdvisorStatusUpdate = new FormData();
      formAdvisorStatusUpdate.append("id", id);
      formAdvisorStatusUpdate.append("status", advisorStatus ? 0 : 1);
      formAdvisorStatusUpdate.append("device_type", "web");
      formAdvisorStatusUpdate.append("user_id", id);
      formAdvisorStatusUpdate.append("user_type", "advisor");
      const result = await updateAdvisorStatus(
        formAdvisorStatusUpdate
      ).unwrap();

      if (result?.result == "1") {
        setIsStatusLoading(false);
        toast.success("Status Updated!");
      } else if (result?.result == "0") {
        setIsStatusLoading(false);
        dispatch(setErrorModalMessage(result?.message));
        dispatch(setIsErrorModalOpen(true));
      } else if (result?.result == "-1") {
        setIsStatusLoading(false);
        dispatch(setErrorModalMessage(result?.message));
        dispatch(setIsErrorModalOpen(true));
      }
    } catch (err) {
      if (err?.status === 401) {
        setIsStatusLoading(false);
        handleLogout();
      }
    }
  };

  const handleSelectChange = async (event) => {
    try {
      const selectedValue = event.target.value;
      setIsStatusLoading(true);
      const formAdvisorLiveRateUpdate = new FormData();
      formAdvisorLiveRateUpdate.append("advisor_id", id);
      formAdvisorLiveRateUpdate.append("live_rate", selectedValue);
      formAdvisorLiveRateUpdate.append("device_type", "web");
      formAdvisorLiveRateUpdate.append("user_id", id);
      formAdvisorLiveRateUpdate.append("user_type", "advisor");
      const result = await updateAdvisorLiveRate(
        formAdvisorLiveRateUpdate
      ).unwrap();
      if (result?.result == "1") {
        toast.success("Live Rate Updated!");
        setIsStatusLoading(false);
        dispatch(setGetadvisorSelectedRate(result?.live_rate));
      } else {
        setIsStatusLoading(false);
        dispatch(setErrorModalMessage(result?.message));
        dispatch(setIsErrorModalOpen(true));
      }
    } catch (err) {
      if (err?.status === 401) {
        setIsStatusLoading(false);
        handleLogout();
      }
    }
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      {isStatusLoading ? (
        <Loader height={30} width={30} color={"#3A86F4"} />
      ) : (
        <>
          <div>
            <div>
              <div className={`group relative`}>
                <div className="cursor-pointer hover:bg-gray-100 rounded-full p-2">
                  <HiOutlineDotsVertical className="text-[22px]  " />
                </div>
                <div className="absolute right-0 hidden group-hover:block z-40 ">
                  <div className="py-3">
                    <div className="w-4 h-4 absolute right-[20px] mt-1 bg-white rotate-45"></div>
                  </div>
                  <div
                    className="p-4 bg-white flex flex-col gap-2"
                    style={{
                      width: "270px",
                      borderRadius: "8px",
                    }}
                  >
                    {/* Text Status  */}
                    <div className="flex justify-between items-center gap-4">
                      <h6 className="text-md text-gray-700 font-semibold">
                        Text Status
                      </h6>
                      <button
                        onClick={toggleStatus}
                        className={`w-12 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out ${
                          advisorStatus ? "bg-[#3A86F4]" : "bg-gray-300"
                        }`}
                      >
                        <div
                          className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${
                            advisorStatus ? "translate-x-6" : "translate-x-0"
                          }`}
                        ></div>
                      </button>
                    </div>

                    {/* Rush Status */}
                    <div className="flex justify-between items-center gap-4">
                      <h6 className="text-md text-gray-700 font-semibold">
                        Faast Status
                      </h6>
                      <button
                        onClick={toggleRush}
                        className={`w-12 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out ${
                          advisorRushStatus ? "bg-[#3A86F4]" : "bg-gray-300"
                        }`}
                      >
                        <div
                          className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${
                            advisorRushStatus
                              ? "translate-x-6"
                              : "translate-x-0"
                          }`}
                        ></div>
                      </button>
                    </div>

                    {/* Live Status  */}
                    <div className="flex justify-between items-center">
                      <h6 className="text-md text-gray-700 font-semibold">
                        Live Status
                      </h6>
                      <button
                        onClick={toggle}
                        className={`w-12 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out ${
                          advisorLiveStatus ? "bg-[#3A86F4]" : "bg-gray-300"
                        }`}
                      >
                        <div
                          className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${
                            advisorLiveStatus
                              ? "translate-x-6"
                              : "translate-x-0"
                          }`}
                        ></div>
                      </button>
                    </div>

                    {/* Credit Rate */}

                    <div className="flex justify-center mt-0">
                      <form className="w-full">
                        <label
                          htmlFor="liverate"
                          className="text-md text-gray-700 font-semibold"
                        >
                          Live Rate
                        </label>
                        <select
                          id="liverate"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[#3A86F4] dark:border-[#3A86F4] dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none mt-2 cursor-pointer"
                          value={getadvisorSelectedRate || ""}
                          onChange={handleSelectChange}
                        >
                          {getAdvisorLiveRate?.map((liveRate, index) => {
                            const { live_rate } = liveRate;
                            return <option key={index}>{live_rate}</option>;
                          })}
                        </select>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AdvisorStatus;
