import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePubNub } from "pubnub-react";
import {
  setChatBodyLiveFilteredData,
  setChatBodyLiveFirebaseData,
  setChatHeadLiveFilteredData,
  setChatHeadSelectedUser,
  setIsInboxIsClicked,
  setIsReEngagementClicked,
} from "../features/chat/chatSlice";
import {
  setAdvisorLoginResponse,
  setIsAuthenticated,
} from "../features/login/loginSlice";

const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pubnub = usePubNub();

  const handleLogout = useCallback(async () => {
    // await offlineAdvisorLiveStatus(); // Can also be called before or after logout based on requirements
    localStorage.removeItem("advisorsData");
    dispatch(setChatBodyLiveFilteredData([]));
    dispatch(setChatHeadLiveFilteredData([]));
    dispatch(setAdvisorLoginResponse(null));
    dispatch(setChatHeadSelectedUser({}));
    dispatch(setChatBodyLiveFirebaseData([]));
    dispatch(setIsAuthenticated(false));
    dispatch(setIsInboxIsClicked(true));
    dispatch(setIsReEngagementClicked(false));
    pubnub.unsubscribeAll();
    navigate("/login");
  }, [dispatch, navigate]);

  return { handleLogout };
};

export default useLogout;
