import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEYLIVE,
  authDomain: process.env.REACT_APP_AUTHDOMAINLIVE,
  databaseURL: process.env.REACT_APP_DATABASEURLLIVE,
  projectId: process.env.REACT_APP_PROJECTIDLIVE,
  storageBucket: process.env.REACT_APP_STORAGEBUCKETLIVE,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERIDLIVE,
  appId: process.env.REACT_APP_APPIDLIVE,
  measurementId: process.env.REACT_APP_MEASUREMENTIDLIVE,
};

export const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const auth = getAuth(app);
