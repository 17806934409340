import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import AWS from "aws-sdk";
import { Camera } from "react-camera-pro";
import Loader from "../../components/Loader";
import { AiFillDelete } from "react-icons/ai";
import upload from "../../assets/upload.png";
import web from "../../assets/web.png";
import moment from "moment";
import { setIsPictureModalOpen, setSelectedFile } from "./modalSlice";
import {
  setAdvisorMessage,
  setLiveChatImage,
  setLiveChatImageUrl,
} from "../chat/chatSlice";
import getUserId from "../../utils/helper/getUserId";
import { useSendMessage } from "../../hooks/useSendMessage";

const PictureModal = () => {
  const dispatch = useDispatch();
  const { handleSendMessagetoClient } = useSendMessage();
  const [cam, showCam] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [selectedFile, setSelectedFileLocal] = useState(null); // Local state for file
  const webcamRef = useRef(null);
  const { isPictureModalOpen } = useSelector((state) => state.modal);
  const { userSessionData, advisorMessage, liveChatImage, liveChatImageUrl } =
    useSelector((state) => state.chat);
  const { client_id } = userSessionData;
  const { advisorLoginResponse } = useSelector((state) => state.login);
  const userId = getUserId(advisorLoginResponse);

  const s3 = new AWS.S3({
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEYIDAWS,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEYAWS,
  });

  const showCamera = () => {
    showCam(true);
  };

  const capture = useCallback(() => {
    setIsImageLoading(true);
    const imageSrc = webcamRef.current.takePhoto();
    if (imageSrc) {
      fetch(imageSrc)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "image.png", { type: "image/png" });
          handleFileChange(file);
        });

      showCam(false);
      dispatch(setLiveChatImage(imageSrc));
    }
  }, [webcamRef]);

  const handleFileChange = async (e) => {
    setIsImageLoading(true);
    const file = e?.target?.files[0] || e;
    setSelectedFileLocal(file); // Set local state for file

    if (e?.target?.files[0]) {
      dispatch(setLiveChatImage(URL.createObjectURL(e.target.files[0])));
    }

    const name = `${client_id}-to-${userId}/${moment(new Date()).format(
      "YYYY-MM-DD"
    )}-${moment(new Date()).format("HH-MM-SS")}.jpg`;

    const newFile = new File([file], name, { type: file.type });

    const params = {
      Bucket: "mytarotadvisorvideos",
      Key: name,
      Body: newFile,
      ContentType: newFile.type,
      ACL: "public-read", // Adjust as needed
    };
    s3.upload(params, (err, data) => {
      if (err) {
        console.error("Error uploading file:", err);
        setIsImageLoading(false);
        return;
      }
      dispatch(setLiveChatImageUrl(data.Location));
      setIsImageLoading(false);
    });
  };

  const deletePhoto = () => {
    setSelectedFileLocal(null);
    dispatch(setLiveChatImageUrl(null));
    dispatch(setLiveChatImage(null));
  };

  const addPicture = () => {
    handleSendMessagetoClient();
    dispatch(setLiveChatImage(null));
    dispatch(setIsPictureModalOpen(false));
    setSelectedFileLocal(null);
    dispatch(setAdvisorMessage(""));
  };

  return (
    <AnimatePresence>
      {isPictureModalOpen && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[99999999999] backdrop-blur-sm "
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="flex flex-col bg-white min-w-80 w-fit py-8 px-12 relative rounded-lg "
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {!cam && (
              <div className="flex flex-col justify-center items-start space-y-2 px-3 pb-2 mt-2">
                <span className="font-semibold text-2xl leading-6 tracking-tight text-[#1d170f]">
                  Upload Attachment
                </span>
                <p className="font-normal text-base leading-6 tracking-tight text-[#595f62] opacity-90">
                  Would you like to...
                </p>
              </div>
            )}
            {!cam && (
              <>
                <input
                  id="fileInput"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  accept=".jpg, .jpeg, .png"
                />
                <div className="flex flex-col justify-center items-center space-y-2 my-6">
                  <label
                    htmlFor="fileInput"
                    className="flex flex-col w-11/12 max-h-[180px] p-4 justify-center items-center space-y-2 rounded-lg border border-dashed border-[#cbcbcb] bg-[#f6f6f6] cursor-pointer text-center"
                  >
                    <img src={upload} alt="upload" onClick={showCamera} />
                    <h5 className="text-base font-normal leading-5 tracking-wide opacity-90 text-[#25373f]">
                      <span className="text-primary text-[#3a86f4] underline">
                        Click to Upload
                      </span>
                    </h5>
                    <h6>(Maximum file size 10 MB)</h6>
                  </label>

                  <div className="divider">Or</div>

                  <div
                    onClick={showCamera}
                    className="w-11/12 flex p-4 justify-center items-center space-x-2 rounded-lg border border-dashed border-[rgba(58, 134, 244, 0.5)] bg-[rgba(58, 134, 244, 0.05)] cursor-pointer"
                  >
                    <img src={web} className="pointing" />
                    <h5 className="text-base font-normal leading-5 tracking-wide opacity-90 text-[#25373f]">
                      Capture Photo
                    </h5>
                  </div>

                  {liveChatImage && (
                    <>
                      {isImageLoading ? (
                        <div className="flex justify-center">
                          <Loader
                            width={"40px"}
                            height={"40px"}
                            color={"#FF6157"}
                          />
                        </div>
                      ) : (
                        <>
                          <img
                            src={liveChatImage}
                            alt="screenshot"
                            className="w-auto h-20"
                          />
                          <div className="flex justify-center mt-3">
                            <AiFillDelete
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={deletePhoto}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>

                <div className="flex justify-center">
                  <input
                    placeholder="Write caption here..."
                    onChange={(e) =>
                      dispatch(setAdvisorMessage(e.target.value))
                    }
                    value={advisorMessage}
                    className="w-[480px] h-[52px] border border-[#d2cfcf] rounded-lg p-4 outline-none"
                  />
                </div>
                <div className="flex justify-center mt-2 gap-3 mb-2">
                  <button
                    className="max-w-[50%] px-4 py-2 rounded-lg border border-[rgba(89, 95, 98, 0.2)] bg-white shadow-sm text-[#595f62] text-base font-medium leading-6"
                    onClick={() => dispatch(setIsPictureModalOpen(false))}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={addPicture}
                    className="max-w-[50%] px-4 py-2 rounded-lg bg-[#3a86f4] shadow-[0_3px_0_0_#2f71d0] text-white text-base font-medium leading-6"
                  >
                    Upload
                  </button>
                </div>
              </>
            )}
            {cam === true && (
              <>
                {liveChatImage === null && (
                  <section className="w-[480px] h-[480px] rounded-lg">
                    <Camera ref={webcamRef} className="rounded-lg" />
                    <div className="flex items-center justify-center gap-3 absolute left-1/2 transform -translate-x-1/2 bottom-8">
                      <button
                        onClick={(e) => showCam(false)}
                        className="py-2.5 px-4 rounded-lg bg-blue-500 shadow-lg text-white text-base font-medium leading-6 z-10"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={capture}
                        className="py-2.5 px-4 rounded-lg bg-blue-500 shadow-lg text-white text-base font-medium leading-6 z-10"
                      >
                        Capture photo
                      </button>
                    </div>
                  </section>
                )}
              </>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default PictureModal;
