import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";

import { setIsAcknowledgedModalOpen } from "./modalSlice";
import { useAcknowledgeAdminNotificationMutation } from "../../app/api/advisor";
import { getRemainingTime } from "../../utils/helper/getRemainingTime";
import useGetAdvisorInfo from "../../hooks/useGetAdvisorInfo";
import {
  setIsAdminStatusOk,
  setIsMessageRepliedSuccessfully,
} from "../chat/chatSlice";
import Loader from "../../components/Loader";
import useLogout from "../../hooks/useLogout";

const AcknowledgedModal = () => {
  const dispatch = useDispatch();
  const [isAcknowledgedLoading, setIsAcknowledgedLoading] = useState(false);
  const { isAcknowledgedModalOpen } = useSelector((state) => state.modal);
  const { chatHeadSelectedUser } = useSelector((state) => state.chat);
  const { advisorInfoData } = useGetAdvisorInfo();
  const [acknowledgeAdminNotification, { error }] =
    useAcknowledgeAdminNotificationMutation();
  const closeModal = () => {
    dispatch(setIsAcknowledgedModalOpen(false));
  };
  const { handleLogout } = useLogout();
  const { id = "" } = advisorInfoData?.user || {};
  const { message_review_id, msg_date } = chatHeadSelectedUser;

  if (error) {
    if (error.status === 401) {
      handleLogout();
    }
  }

  const AcknowledgeAdminHandler = async () => {
    setIsAcknowledgedLoading(true);
    const formData = new FormData();
    formData.append("advisor_id", id);
    formData.append("message_review_id", message_review_id);
    formData.append("notification_id", getRemainingTime(msg_date));
    formData.append("user_id", id);
    formData.append("user_type", "advisor");
    try {
      const result = await acknowledgeAdminNotification(formData);

      if (result?.data?.result == "1") {
        setIsAcknowledgedLoading(false);
        dispatch(setIsAdminStatusOk(false));
        dispatch(setIsMessageRepliedSuccessfully(true));
        closeModal();
      } else {
        setIsAcknowledgedLoading(false);
      }
    } catch (error) {}
  };
  return (
    <AnimatePresence>
      {isAcknowledgedModalOpen && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[99999999999] backdrop-blur-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="flex flex-col bg-white min-w-80 w-fit py-4 px-12 relative rounded-lg"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="flex flex-col gap-4">
              <h6 className="text-center text-lg capitalize">
                Psychic Txt Admin
              </h6>
              <p className="text-center">{chatHeadSelectedUser?.msg_text}</p>
              <button
                className="bg-[#3A86F4] flex justify-center items-center px-7 py-2 rounded-lg text-white text-lg "
                onClick={AcknowledgeAdminHandler}
              >
                {isAcknowledgedLoading ? (
                  <Loader width={30} height={30} color={"#fff"} />
                ) : (
                  "Acknowledge"
                )}
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AcknowledgedModal;
