import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { IoCloseOutline } from "react-icons/io5";

import { setIsLiveChatAIResponseOpen } from "./modalSlice";

const LiveChatSummaryAIModal = () => {
  const disaptch = useDispatch();
  const { isLiveChatAIResponseOpen, isLiveChatAIResponseData } = useSelector(
    (state) => state.modal
  );
  const closeModal = () => {
    disaptch(setIsLiveChatAIResponseOpen(false));
  };
  const stopPropagation = (event) => {
    event.stopPropagation();
  };
  return (
    <AnimatePresence>
      {isLiveChatAIResponseOpen && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[99] backdrop-blur-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={closeModal}
        >
          <motion.div
            className="flex flex-col bg-white max-w-[50%] w-fit py-4 px-12 relative rounded-lg"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.3 }}
            onClick={stopPropagation} // Stop click event from propagating
          >
            <button
              className="absolute right-4 cursor-pointer"
              onClick={closeModal}
            >
              <IoCloseOutline className="text-2xl" />
            </button>

            <div className="mt-5">
              <h6 className="text-left text-[15px] capitalize">
                {isLiveChatAIResponseData}
              </h6>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LiveChatSummaryAIModal;
