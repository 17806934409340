// slices/chatSlice.js
import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    email: "",
    password: "",
    advisorLoginResponse: null,
    advisorJWTToken: null,
    isAuthenticated: false,
  },
  reducers: {
    setAdvisorEmail: (state, action) => {
      state.email = action.payload;
    },
    setAdvisorPassword: (state, action) => {
      state.password = action.payload;
    },
    setAdvisorLoginResponse: (state, action) => {
      state.advisorLoginResponse = action.payload;
    },
    setAdvisorJWTToken: (state, action) => {
      state.advisorJWTToken = action.payload;
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
});

export const {
  setAdvisorEmail,
  setAdvisorPassword,
  setAdvisorLoginResponse,
  setAdvisorJWTToken,
  setIsAuthenticated,
} = loginSlice.actions;

export default loginSlice.reducer;
