import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setIsCallingModalOpen } from "../features/modal/modalSlice";

const createWorker = (workerScript) => {
  const blob = new Blob([workerScript], { type: "application/javascript" });
  const url = URL.createObjectURL(blob);
  return new Worker(url);
};

const useHandleModalTimer = () => {
  const modalTimerWorkerRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const timerWorkerScript = `
      let liveSeconds;
      let isRunning = false;
      self.onmessage = function(e) {
        if (typeof e.data === 'number') {
          liveSeconds = e.data;
        } else if (e.data === 'start' && !isRunning) {
          isRunning = true;
          if (typeof liveSeconds !== 'undefined') {
            self.interval = setInterval(() => {
              liveSeconds -= 1;
              self.postMessage({ type: 'modal', value: liveSeconds });
              if (liveSeconds <= 0) {
                self.postMessage({ type: 'modal', value: 0 });
                clearInterval(self.interval);
                isRunning = false;
              }
            }, 1000);
          }
        } else if (e.data === 'stop') {
          clearInterval(self.interval);
          isRunning = false;
        }
      };
    `;

    modalTimerWorkerRef.current = createWorker(timerWorkerScript);

    const handleWorkerMessage = (e) => {
      const { type, value } = e.data;
      if (type === "modal" && value === 0) {
        dispatch(setIsCallingModalOpen(false));
      }
    };

    modalTimerWorkerRef.current.onmessage = handleWorkerMessage;

    return () => {
      modalTimerWorkerRef.current.terminate();
    };
  }, [dispatch]);

  const getTimeDifferenceFromUtc = (utcTime) => {
    if (!utcTime) return 0;

    try {
      const parsedUtcTime = new Date(utcTime.replace(" ", "T") + "Z"); // Ensure the format is ISO 8601
      const currentTime = new Date();
      const durationInMillis = Math.abs(currentTime - parsedUtcTime);
      return durationInMillis / 1000;
    } catch (e) {
      return 0;
    }
  };

  const startModalTimer = (createdAt) => {
    const elapsedTime = getTimeDifferenceFromUtc(createdAt);
    const remainingTime = 55 - elapsedTime;
    if (remainingTime > 0) {
      modalTimerWorkerRef.current.postMessage(remainingTime);
      modalTimerWorkerRef.current.postMessage("start");
    } else {
      dispatch(setIsCallingModalOpen(false));
    }
  };

  const stopModalTimer = () => {
    modalTimerWorkerRef.current.postMessage("stop");
  };

  return { startModalTimer, stopModalTimer };
};

export default useHandleModalTimer;
