import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setIsAuthenticated } from "../features/login/loginSlice";
import { base_url, version } from "../utils/helper/helper";

const baseQuery = fetchBaseQuery({
  baseUrl: `${base_url}API/${version}/`,
  prepareHeaders: (headers, { getState }) => {
    const token = getState()?.login?.advisorJWTToken;
    const localStorageJWT = JSON.parse(
      localStorage.getItem("advisorsData")
    )?.jwt;

    if (token || localStorageJWT) {
      headers.set("Authorization", `Bearer ${token || localStorageJWT}`);
    }
    return headers;
  },
});

// Custom baseQuery with error handling
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    const { status } = result.error;

    if (status === 401) {
      // Dispatch an action or handle unauthorized access
      // Optionally, you can attempt to refresh the token here
    }
    // Handle other status codes if necessary
  }

  return result;
};

export const userApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Login", "Chat", "Advisor"],
  endpoints: (builder) => ({
    // Define your endpoints here
  }),
});
