import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { setIsRefundModalOpen } from "./modalSlice";
import useGetAdvisorInfo from "../../hooks/useGetAdvisorInfo";
import {
  setGetReplyThisClientData,
  setIsMessageRepliedSuccessfully,
} from "../chat/chatSlice";
import Loader from "../../components/Loader";
import { useMessageRefundMutation } from "../../app/api/advisor";
import useLogout from "../../hooks/useLogout";
import toast from "react-hot-toast";

const RefundModal = () => {
  const dispatch = useDispatch();
  const [refundMessage, setRefundMessage] = useState("");
  const [isRefundLoading, setIsRefundLoading] = useState(false);
  const { handleLogout } = useLogout();
  const [messageRefund, { error }] = useMessageRefundMutation();
  const { isRefundModalOpen } = useSelector((state) => state.modal);
  const { getReplyThisClientData } = useSelector((state) => state.chat);
  const { advisorInfoData } = useGetAdvisorInfo();
  const { id = "" } = advisorInfoData?.user || {};

  if (error) {
    if (error.status === 401) {
      handleLogout();
    }
  }
  const closeModal = () => {
    dispatch(setIsRefundModalOpen(false));
  };
  const sendRefundData = async () => {
    if (refundMessage.trim().length == 0) {
      toast("Please enter a message", {
        duration: 2000,
        style: { zIndex: 9999 },
      });
      return;
    }
    setIsRefundLoading(true);
    const formData = new FormData();
    formData.append("user_id", id ?? "");
    formData.append("user_type", "advisor");
    formData.append("transaction_type", "refund");
    formData.append(
      "client_message_review_id",
      getReplyThisClientData?.message_review_id ?? ""
    );
    formData.append("description", refundMessage ?? "");
    try {
      const result = await messageRefund(formData);
      if (result?.data?.result == "1") {
        setRefundMessage("");
        closeModal();
        setIsRefundLoading(false);
        dispatch(setIsMessageRepliedSuccessfully(true));
        dispatch(setGetReplyThisClientData({}));
      }
    } catch (error) {}
  };
  return (
    <AnimatePresence>
      {isRefundModalOpen && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[999] backdrop-blur-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="flex flex-col bg-white min-w-80 w-fit py-4 px-12 relative rounded-lg"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div>
              <div className="text-lg capitalize">
                <h6 className="text-base text-gray-700 mb-2">Refund</h6>
                <textarea
                  type="text"
                  placeholder="Type your info message"
                  className="border-black border-solid border-[1px] resize-none outline-none rounded-lg shadow-lg p-2"
                  rows={5}
                  cols={40}
                  onChange={(e) => setRefundMessage(e.target.value)}
                  value={refundMessage}
                />
              </div>
              <div className="flex items-center justify-between mt-2">
                <button
                  className="py-2 px-6 bg-[#3A86F4] text-white rounded-lg shadow-lg"
                  onClick={sendRefundData}
                  disabled={isRefundLoading}
                >
                  {isRefundLoading ? (
                    <Loader width={30} height={30} color={"#fff"} />
                  ) : (
                    "Refund"
                  )}
                </button>
                {!isRefundLoading && (
                  <button
                    className="py-2 px-6 bg-red-500 text-white rounded-lg shadow-lg"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default RefundModal;
