import React, { useEffect, useState } from "react";
import ConversationItem from "./ConversationItem";
import {
  useGetAdvisorChatHeadMutation,
  useGetChatHeadReEngagementMutation,
} from "../app/api/chat";
import formatTimeDifference from "../utils/helper/timeFormatter";
import { useDispatch, useSelector } from "react-redux";
import {
  setChatHeadLiveFilteredData,
  setChatHeadPagination,
  setChatHeadPaginationAdding,
  setChatHeadReEngagementData,
  setChatHeadSelectedUser,
  setIsAdminStatusOk,
  setIsMessageRepliedSuccessfully,
  setIsReEngagementRepliedSuccessfully,
} from "../features/chat/chatSlice";
import getUserId from "../utils/helper/getUserId";
import Loader from "./Loader";
import { searchByName } from "../utils/helper/searchByName";
import useLogout from "../hooks/useLogout";
import getTimeDifferenceFromUtc from "../utils/helper/getTimeDifferenceFromUtc";
import { useSendMessage } from "../hooks/useSendMessage";

const advisorID = JSON.parse(localStorage.getItem("advisorsData"));

const Conversation = ({ searchTerm, page, setIsGetChatHeadIsLoading }) => {
  const [userChatHeads, setUserChatHeads] = useState([]);
  // const { userChatHeads,
  //   setUserChatHeads,} =useSendMessage
  const { handleLogout } = useLogout();
  const dispatch = useDispatch();
  const { advisorLoginResponse } = useSelector((state) => state.login);
  const {
    isMessageRepliedSuccessfully,
    userSessionData,
    getPubnubMessages,
    getPubnubMessageInfo,
    isInboxIsClicked,
    isReEngagementClicked,
    chatHeadReEngagementData,
    isReEngagementRepliedSuccessfully,
    chatHeadPagination,
  } = useSelector((state) => state.chat);
  const { client_displayname, client_id, session_status } = userSessionData;
  const [getAdvisorChatHead, { isLoading, error }] =
    useGetAdvisorChatHeadMutation();
  const [getChatHeadReEngagement, { isLoading: isReEngagementLoading }] =
    useGetChatHeadReEngagementMutation();
  const userId = getUserId(advisorLoginResponse);

  if (error) {
    if (error.status === 401) {
      handleLogout();
    }
  }
  const fetchAdvisorData = async () => {
    try {
      const formData = new FormData();
      formData.append("id", userId);
      formData.append("user_id", userId);
      formData.append("type", "advisor");
      formData.append("user_type", "advisor");
      formData.append("device_type", "web");
      formData.append("page", chatHeadPagination);
      formData.append("limit", "0");
      const response = await getAdvisorChatHead(formData);
      const filterData = response?.data?.message;

      dispatch(setChatHeadLiveFilteredData(filterData));
      setIsGetChatHeadIsLoading(false);

      if (
        session_status === "A" ||
        session_status === "S" ||
        session_status === "X"
      ) {
        dispatch(setChatHeadSelectedUser(userSessionData));
      } else if (chatHeadPagination < 1 && isInboxIsClicked) {
        dispatch(setChatHeadSelectedUser(filterData?.[0] || []));
        setUserChatHeads(filterData);
        dispatch(setIsMessageRepliedSuccessfully(false));
      } else if (chatHeadPagination >= 1 && isInboxIsClicked) {
        setUserChatHeads((prevData) => [...prevData, ...filterData]);
      }
    } catch (err) {
      if (err?.status === 401) {
        handleLogout();
      }
    }
  };
  const fetchReEngageMentChatHeadData = async () => {
    try {
      const formData = new FormData();
      formData.append("id", userId);
      formData.append("user_id", userId);
      formData.append("type", "advisor");
      formData.append("user_type", "advisor");
      formData.append("device_type", "web");
      formData.append("page", 0);
      formData.append("limit", "0");

      const response = await getChatHeadReEngagement(formData);
      const filterData = response?.data?.message;
      if (isReEngagementClicked) {
        setUserChatHeads([]);
        dispatch(setChatHeadLiveFilteredData(filterData));
        dispatch(setChatHeadReEngagementData(filterData));
        dispatch(setChatHeadSelectedUser(filterData?.[0] || []));
        dispatch(setIsReEngagementRepliedSuccessfully(false));
        dispatch(setChatHeadPagination(0));
        dispatch(setChatHeadPaginationAdding(0));
      }
    } catch (err) {
      if (err?.status === 401) {
        handleLogout();
      }
    }
  };

  useEffect(() => {
    fetchAdvisorData();
  }, [userId, chatHeadPagination]);

  useEffect(() => {
    if (isMessageRepliedSuccessfully) fetchAdvisorData();
  }, [isMessageRepliedSuccessfully]);
  useEffect(() => {
    if (isReEngagementClicked || isReEngagementRepliedSuccessfully)
      fetchReEngageMentChatHeadData();
  }, [isReEngagementClicked, isReEngagementRepliedSuccessfully]);

  function getRemainingTime(object, dateKey, messageType) {
    const targetDuration =
      messageType === "text" ? 16 * 60 * 60 * 1000 : 1 * 60 * 60 * 1000; // 16 hours or 1 hour in milliseconds
    const date = new Date(object[dateKey]);
    const currentTime = new Date();
    const timeDifference =
      date.getTime() + targetDuration - currentTime.getTime();
    return timeDifference;
  }

  const updateAdvisorsData = (getPubnubMessages) => {
    if (getPubnubMessages) {
      const { sender_id, ...newMessage } = getPubnubMessages;

      setUserChatHeads((prevData) => {
        // Find the index of the item with the given sender_id
        const existingIndex = prevData.findIndex((item) => {
          const itemClientId =
            item?.sender_id === advisorID ? item?.receiver_id : item?.sender_id;
          return itemClientId === sender_id;
        });

        if (existingIndex !== -1) {
          // If item exists, update the existing object with new messages
          const updatedData = prevData.map((item, index) => {
            if (index === existingIndex) {
              const updatedMessages = item.messages
                ? [...item.messages, newMessage]
                : [newMessage];

              // Sort the updated messages based on remaining time
              updatedMessages.sort((a, b) => {
                const remainingTimeA = getRemainingTime(
                  a,
                  "msg_date",
                  a.message_type
                );
                const remainingTimeB = getRemainingTime(
                  b,
                  "msg_date",
                  b.message_type
                );
                return remainingTimeA - remainingTimeB;
              });

              return {
                ...item,
                messages: updatedMessages,
              };
            }
            return item;
          });

          return updatedData;
        } else {
          // If item does not exist, add a new object with a messages array
          const newMessages = [
            { ...getPubnubMessages, messages: [newMessage] },
          ];

          // Sort the new messages based on remaining time
          newMessages.sort((a, b) => {
            const remainingTimeA = getRemainingTime(
              a,
              "msg_date",
              a.message_type
            );
            const remainingTimeB = getRemainingTime(
              b,
              "msg_date",
              b.message_type
            );
            return remainingTimeA - remainingTimeB;
          });

          return [...prevData, ...newMessages];
        }
      });
    }
  };

  useEffect(() => {
    updateAdvisorsData(getPubnubMessages);
  }, [getPubnubMessages]);

  useEffect(() => {
    if (getPubnubMessageInfo) {
      setUserChatHeads((prevData) => {
        return prevData.map((item) => {
          if (item.sender_id === getPubnubMessageInfo.sender_id) {
            return {
              ...item,
              is_advisor_info: getPubnubMessageInfo.is_advisor_info || 0,
            };
          }
          return item;
        });
      });
    }
  }, [getPubnubMessageInfo]);

  useEffect(() => {
    if (
      userChatHeads.some(
        (advisorProfile) =>
          advisorProfile?.message_type === "advisor_com" &&
          advisorProfile?.status === "0"
      )
    ) {
      dispatch(setIsAdminStatusOk(true));
    } else if (
      userChatHeads.some(
        (advisorProfile) =>
          advisorProfile?.message_type === "advisor_com" &&
          advisorProfile?.status === "1"
      )
    ) {
      dispatch(setIsAdminStatusOk(false));
    }
  }, [userChatHeads, dispatch]);

  if (isReEngagementLoading || (isLoading && chatHeadPagination < 1)) {
    return (
      <div className="flex justify-center items-center h-[80%]">
        <Loader width={"50px"} height={"50px"} color={"#3A86F4"} />
      </div>
    );
  }

  const filteredChatHeads = searchTerm
    ? searchByName(searchTerm, userChatHeads, advisorID?.user?.id)
    : userChatHeads;

  // function getRemainingTime(object, dateKey) {
  //   const targetDuration = 16 * 60 * 60 * 1000; // 16 hours in milliseconds
  //   const date = new Date(object[dateKey]);
  //   const currentTime = new Date();
  //   const timeDifference =
  //     date.getTime() + targetDuration - currentTime.getTime();
  //   return timeDifference;
  // }

  // const sortDataByRemainingTime = (data) => {
  //   return [...data]?.slice()?.sort((a, b) => {
  //     const aPendingTime =
  //       a?.pending_timer_date && a?.pending_timer_date !== "0"
  //         ? getRemainingTime(a, "pending_timer_date")
  //         : a?.messages?.[0]
  //         ? getRemainingTime(a.messages[0], "msg_date")
  //         : Infinity; // or some large number to push it to the end

  //     const bPendingTime =
  //       b?.pending_timer_date && b?.pending_timer_date !== "0"
  //         ? getRemainingTime(b, "pending_timer_date")
  //         : b?.messages?.[0]
  //         ? getRemainingTime(b.messages[0], "msg_date")
  //         : Infinity; // or some large number to push it to the end

  //     return aPendingTime - bPendingTime;
  //   });
  // };

  function getRemainingTime(object, dateKey, messageType) {
    const targetDuration =
      messageType === "text" ? 16 * 60 * 60 * 1000 : 1 * 60 * 60 * 1000; // 16 hours or 1 hour in milliseconds
    const date = new Date(object[dateKey]);
    const currentTime = new Date();
    const timeDifference =
      date.getTime() + targetDuration - currentTime.getTime();
    return timeDifference;
  }

  const sortDataByRemainingTime = (data) => {
    return [...data]?.slice()?.sort((a, b) => {
      const isAAdvisorCom0 =
        a?.message_type === "advisor_com" && a?.status === "0";
      const isBAdvisorCom0 =
        b?.message_type === "advisor_com" && b?.status === "0";

      if (isAAdvisorCom0 && !isBAdvisorCom0) return -1; // a goes before b
      if (!isAAdvisorCom0 && isBAdvisorCom0) return 1; // b goes before a

      const aMessageType = a?.messages?.[0]?.message_type || null;
      const bMessageType = b?.messages?.[0]?.message_type || null;

      const aPendingTime =
        a?.pending_timer_date && a?.pending_timer_date !== "0"
          ? getRemainingTime(a, "pending_timer_date", a?.message_type)
          : a?.messages?.[0]
          ? getRemainingTime(a.messages[0], "msg_date", aMessageType)
          : Infinity; // or some large number to push it to the end

      const bPendingTime =
        b?.pending_timer_date && b?.pending_timer_date !== "0"
          ? getRemainingTime(b, "pending_timer_date", b?.message_type)
          : b?.messages?.[0]
          ? getRemainingTime(b.messages[0], "msg_date", bMessageType)
          : Infinity; // or some large number to push it to the end

      return aPendingTime - bPendingTime;
    });
  };

  // Example usage:
  const sortedData = sortDataByRemainingTime(filteredChatHeads);
  // const sortedChatHeads = sortDataByRemainingTime(filteredChatHeads);
  return (
    <div className="w-full h-[90%]">
      {session_status === "A" ||
      session_status === "S" ||
      session_status === "X" ? (
        <ConversationItem
          id={""}
          senderId={client_id}
          message={""}
          time={"now"}
          name={client_displayname}
          advisorProfile={userSessionData}
        />
      ) : isInboxIsClicked ? (
        sortedData?.map((advisorProfile, index) => {
          const {
            receiver_displayname,
            sender_displayname,
            sender_id,
            receiver_id,
            msg_date,
            msg_text,
            id,
          } = advisorProfile || {};
          if (
            advisorProfile?.message_type === "advisor_com" &&
            advisorProfile?.status === "0"
          ) {
            dispatch(setIsAdminStatusOk(true));
          }
          const clientName =
            sender_id === advisorID ? receiver_displayname : sender_displayname;
          const clientid = sender_id === advisorID ? receiver_id : sender_id;

          return (
            <ConversationItem
              key={index}
              id={id}
              senderId={clientid}
              message={msg_text}
              time={formatTimeDifference(msg_date)}
              name={clientName}
              advisorProfile={advisorProfile}
            />
          );
        })
      ) : (
        chatHeadReEngagementData?.map((advisorProfile, index) => {
          const {
            receiver_displayname,
            sender_displayname,
            sender_id,
            receiver_id,
            msg_date,
            msg_text,
            id,
          } = advisorProfile || {};
          if (
            advisorProfile?.message_type === "advisor_com" &&
            advisorProfile?.status === "0"
          ) {
            dispatch(setIsAdminStatusOk(true));
          }
          const clientName =
            sender_id === advisorID ? receiver_displayname : sender_displayname;
          const clientid = sender_id === advisorID ? receiver_id : sender_id;

          return (
            <ConversationItem
              key={index}
              id={id}
              senderId={clientid}
              message={msg_text}
              time={formatTimeDifference(msg_date)}
              name={clientName}
              advisorProfile={advisorProfile}
            />
          );
        })
      )}
    </div>
  );
};

export default Conversation;
