import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import getFirstLetter from "../utils/helper/getFirstLetter";
import arrow from "../assets/chevron-double-up.svg";
import { motion } from "framer-motion";
import {
  setGetReplyThisClientData,
  setIsReplyThisClient,
} from "../features/chat/chatSlice";
import { setIsRefundModalOpen } from "../features/modal/modalSlice";
import { colorClasses } from "../utils/helper/colorClasses";
const advisorData = JSON.parse(localStorage.getItem("advisorsData"));

const ChatMessage = ({ message, userSessionData }) => {
  const dispatch = useDispatch();
  const { session_status, client_displayname } = userSessionData;
  const { chatHeadSelectedUser } = useSelector((state) => state.chat);

  const clientName =
    chatHeadSelectedUser?.sender_id === advisorData
      ? chatHeadSelectedUser?.receiver_displayname
      : chatHeadSelectedUser?.sender_displayname;
  const colorClass =
    colorClasses[
      session_status === "A" || session_status === "S" || session_status === "X"
        ? getFirstLetter(client_displayname)
        : getFirstLetter(clientName)
    ] || "bg-gray-500";

  const renderLiveChatStart = (formattedTime) => (
    <div className="flex justify-center my-2">
      <span className="flex justify-center items-center gap-2  px-4 py-2 rounded-lg">
        <span className="text-[#008033]"> Live Chat Started</span>
        <p className="text-sm text-[#1C2B2699]">{`(${formattedTime})`}</p>
      </span>
    </div>
  );

  const renderLiveChatResume = (msg_text, display_message) => (
    <div className="flex justify-center my-2">
      <span className="bg-gray-200 px-4 py-2 rounded-lg">
        {msg_text
          ? msg_text
          : display_message === ""
          ? "Chat resumed by client"
          : ""}
      </span>
    </div>
  );

  const renderLiveChatContinue = (
    live_extended_credit,
    available,
    exactMinutes
  ) => (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="flex justify-center my-2 bg-[#00A5421A]"
    >
      <span className="  py-[6px] px-[18px] rounded-lg flex items-center gap-4">
        <img src={arrow} alt="arrow icon" />
        <div className="flex flex-col ">
          <div className="flex flex-col justify-start text-sm font-normal">
            <h6 className="text[#25373F]">{live_extended_credit}</h6>
            <p className="text-[#595F62]">
              {available?.[0]}{" "}
              <span className="text-[#FF6157]">{exactMinutes?.[0]}</span>
            </p>
          </div>
        </div>
      </span>
    </motion.div>
  );

  const renderLiveChatSummary = (
    message,
    isDurationGreaterThan2Minutes,
    isLessThan15DaysAgo
  ) => (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={`my-2 flex justify-end  ${
        message.sender_type === "advisor" ? "flex justify-end" : ""
      }`}
    >
      <div
        className={`w-2/4 bg-[#3A86F4] py-2 px-4 rounded-s-lg rounded-br-lg`}
      >
        <p>
          <span className="text-white">
            {
              message.msg_text
                ?.split("Credits Used:")[0]
                ?.split("Chat Duration:")[0]
            }
          </span>
          <hr className="bg-white my-2 text-white" />
          <span className="mb-0 gap-3 grid grid-cols-12 divide-x text-sm">
            <div className="flex items-center col-span-6 gap-2 justify-center ">
              <h4 className="font-normal text-[#fff]">Chat Duration:</h4>
              <h5 className="font-medium text-[#39ff88]">
                {
                  message.msg_text
                    ?.split("Credits Used:")[0]
                    ?.split("Chat Duration:")[1]
                }
              </h5>
            </div>
            <div className="flex items-center col-span-6 gap-2 justify-center">
              <h4 className="font-normal text-[#fff]">Credits Used:</h4>
              <h5 className="font-medium text-[#39ff88]">
                {message.msg_text?.split("Credits Used:")[1]}
              </h5>
            </div>
          </span>
        </p>
      </div>
    </motion.div>
  );

  const renderNotice = (formattedDate, msg_text, id) => (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={`my-2 flex ${
        message.sender_type === "client"
          ? "justify-start gap-4 "
          : "justify-end"
      }`}
    >
      <div
        className={`${message.sender_type === "client" ? "w-[45%]" : "w-2/4"}`}
      >
        <span className="text-xs text-gray-500 ">{formattedDate}</span>
        <p
          id={id}
          className={`${
            message.sender_type === "client"
              ? "bg-[#fff] text-black rounded-e-lg rounded-bl-lg"
              : `bg-[#3A86F4] text-white rounded-s-lg rounded-br-lg`
          } py-2 px-4 mt-1`}
        >
          {msg_text} &nbsp;
        </p>
      </div>
    </motion.div>
  );

  const renderText = (formattedDate, msg_text, id, message) => {
    const parseMsgText = (text) => {
      const sections = {};
      const lines = text.split("\n");
      lines.forEach((line) => {
        const [key, ...rest] = line.split(":");
        if (key && rest.length > 0) {
          sections[key.trim()] = rest.join(":").trim();
        }
      });
      return sections;
    };

    // Parse the message text to check for structured data
    const sections = parseMsgText(msg_text);
    const hasStructuredData = Object.keys(sections).length > 0;

    return (
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        className={`my-2 flex ${
          message?.sender_type === "client"
            ? "justify-start gap-4 "
            : "justify-end"
        }`}
      >
        <div
          className={`${
            message?.sender_type === "client" ? "w-[45%]" : "w-2/4"
          }`}
        >
          <span className="text-xs text-gray-500 ">{formattedDate}</span>
          <div
            id={id}
            className={`${
              message?.sender_type === "client"
                ? "bg-[#fff] text-black rounded-e-lg rounded-bl-lg"
                : `bg-[#3A86F4] text-white rounded-s-lg rounded-br-lg`
            } py-2 px-4 mt-1`}
          >
            {hasStructuredData ? (
              <>
                {sections.Situation && (
                  <div>
                    <strong>Situation:</strong> {sections.Situation}
                  </div>
                )}
                {sections.Question && (
                  <div>
                    <strong>Question:</strong> {sections.Question}
                  </div>
                )}
                {sections.Poi && (
                  <div>
                    <strong>Poi:</strong> {sections.Poi}
                  </div>
                )}
                {sections.Name && (
                  <div>
                    <strong>Name:</strong> {sections.Name}
                  </div>
                )}
                {sections.DOB && (
                  <div>
                    <strong>DOB:</strong> {sections.DOB}
                  </div>
                )}
                {sections.Gender && (
                  <div>
                    <strong>Gender:</strong> {sections.Gender}
                  </div>
                )}
                {sections.Relationship && (
                  <div>
                    <strong>Relationship:</strong> {sections.Relationship}
                  </div>
                )}
              </>
            ) : (
              <p>{msg_text}</p>
            )}
          </div>

          {message?.sender_type === "client" && message?.status === "0" && (
            <div className="flex items-center gap-4 mt-2">
              <div
                className="cursor-pointer w-full flex justify-center "
                onClick={() => {
                  dispatch(setIsReplyThisClient(true));
                  dispatch(setGetReplyThisClientData(message));
                }}
              >
                <h6 className="font-normal text-sm m-2 text-red-500">{`Reply Now (Requires ${
                  message?.response_time === "text"
                    ? "Text"
                    : message?.response_time === "rush_text"
                    ? "FAAST Text"
                    : message?.response_time === "rush_audio"
                    ? "FAAST Audio"
                    : "Audio"
                } Reply)`}</h6>
              </div>
              <button
                className="text-base text-white bg-[#3A86F4] py-1 px-4 rounded-lg shadow-lg"
                onClick={() => {
                  dispatch(setIsRefundModalOpen(true));
                  dispatch(setGetReplyThisClientData(message));
                }}
              >
                More
              </button>
            </div>
          )}
        </div>
      </motion.div>
    );
  };

  const renderAudio = (formattedDate, video_url, id, message) => (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={`my-2 flex ${
        message?.sender_type === "client"
          ? "justify-start gap-4 "
          : "justify-end"
      }`}
    >
      <div
        className={`${message?.sender_type === "client" ? "w-[45%]" : "w-2/4"}`}
      >
        <span className="text-xs text-gray-500 ">{formattedDate}</span>
        <div
          id={id}
          className={`${
            message?.sender_type === "client"
              ? "bg-[#fff] text-black rounded-e-lg rounded-bl-lg"
              : `bg-[#3A86F4] text-white rounded-s-lg rounded-br-lg`
          } py-2 px-4 mt-1`}
        >
          <audio controls preload="auto" className="w-full">
            <source src={video_url} id="src" />
          </audio>
        </div>
        {message?.status === "0" && (
          <div
            className="cursor-pointer w-full flex justify-center "
            onClick={() => {
              dispatch(setIsReplyThisClient(true));
              dispatch(setGetReplyThisClientData(message));
            }}
          >
            <h6 className="font-normal text-sm m-2 text-red-500">{`Reply Now (Requires ${
              message?.response_time === "audio" ? "Audio" : "FAAST Audio"
            } Reply)`}</h6>
          </div>
        )}
      </div>
    </motion.div>
  );
  const psychicTxtAdmin = (formattedDate, msg_text, id) => (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={`my-2 flex ${
        message.sender_type === "client"
          ? "justify-start gap-4 "
          : "justify-end"
      }`}
    >
      <div
        className={`${message.sender_type === "client" ? "w-[45%]" : "w-2/4"}`}
      >
        <span className="text-xs text-gray-500 ">{formattedDate}</span>
        <p
          id={id}
          className={`${
            message.sender_type === "client"
              ? "bg-[#fff] text-black rounded-e-lg rounded-bl-lg"
              : `bg-[#3A86F4] text-white rounded-s-lg rounded-br-lg`
          } py-2 px-4 mt-1`}
        >
          {msg_text} &nbsp;
        </p>
      </div>
    </motion.div>
  );

  const renderInfo = (formattedDate, msg_text, id, message) => (
    <>
      <div className="flex justify-center pt-1">
        <span className="flex justify-center items-center rounded-lg">
          <span className="text-[#008033]">Info Message</span>
        </span>
      </div>

      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        className={`my-2 flex ${
          message?.sender_type === "client"
            ? "justify-start gap-4 "
            : "justify-end"
        }`}
      >
        <div
          className={`${
            message?.sender_type === "client" ? "w-[45%]" : "w-2/4"
          }`}
        >
          <span className="text-xs text-gray-500 ">{formattedDate}</span>
          <p
            id={id}
            className={`${
              message?.sender_type === "client"
                ? "bg-[#fff] text-black rounded-e-lg rounded-bl-lg"
                : `bg-[#3A86F4] text-white rounded-s-lg rounded-br-lg`
            } py-2 px-4 mt-1`}
          >
            {msg_text} &nbsp;
          </p>
        </div>
      </motion.div>
    </>
  );

  const renderPhoto = (formattedDate, msg_text, id, video_url, message) => (
    <div className="my-2 ">
      <div
        className={`my-2 flex ${
          message.sender_type === "client"
            ? "justify-start gap-4 "
            : "justify-end "
        }`}
      >
        {message.sender_type === "client" && (
          <div className="h-10 w-10">
            {/* <Avatar size={30} src={advisorData?.s3_display_picture ? advisorData?.s3_display_picture : ""} /> */}
            <div
              className={`w-10 h-10 m-1 text-2xl flex justify-center items-center rounded-full text-white shadow-md ${colorClass}`}
            >
              {session_status === "A" ||
              session_status === "S" ||
              session_status === "X"
                ? getFirstLetter(client_displayname)
                : getFirstLetter(clientName)}
            </div>
          </div>
        )}
        <div className=" flex flex-col gap-1">
          <span className="text-sm text-gray-500">{formattedDate}</span>
          <div
            className="cursor-pointer w-[400px] h-auto"
            // onClick={() => handleImage(video_url?.split("~")[0])}
          >
            <img src={video_url} alt="image" className="rounded-lg shadow-lg" />
          </div>
          {msg_text.trim().length > 0 && msg_text !== "null" && (
            <div>
              <div
                className={`${
                  message.sender_type === "client"
                    ? "bg-[#fff] text-black rounded-e-lg rounded-bl-lg"
                    : `bg-[#3A86F4] text-white rounded-s-lg rounded-br-lg`
                } py-2 px-4 mt-1`}
              >
                <p id={id}>{msg_text === "null" ? "" : msg_text} &nbsp;</p>
              </div>
              <hr />
            </div>
          )}
        </div>
      </div>
      {message?.status === "0" && (
        <div
          className="cursor-pointer w-full flex justify-center "
          onClick={() => {
            dispatch(setIsReplyThisClient(true));
            dispatch(setGetReplyThisClientData(message));
          }}
        >
          <h6 className="font-normal text-sm m-2 text-red-500">{`Reply Now (Requires ${
            message?.response_time === "text" ||
            message?.response_time === "photo"
              ? "Text"
              : message?.response_time === "rush_text" ||
                message?.response_time === "rush_photo"
              ? "FAAST Text"
              : message?.response_time === "rush_audio"
              ? "FAAST Audio"
              : "Audio"
          } Reply)`}</h6>
        </div>
      )}
    </div>
  );

  const renderLiveChatEnded = (msg_text) => (
    <div className="flex justify-center">
      <span className="bg-gray-300 px-4 py-2 rounded-lg">
        {msg_text ? msg_text : "Live chat ended"}
      </span>
    </div>
  );

  const renderMessageContent = () => {
    const durationPattern = /Chat Duration: (\d{2}:\d{2}:\d{2})/;
    const match = message?.msg_text?.match(durationPattern);

    let isDurationGreaterThan2Minutes;
    if (match) {
      const durationStr = match[1];
      const [hours, minutes, seconds] = durationStr.split(":");
      const durationInSeconds =
        parseInt(hours, 10) * 3600 +
        parseInt(minutes, 10) * 60 +
        parseInt(seconds, 10);

      isDurationGreaterThan2Minutes = durationInSeconds > 120;
    }

    const messageDate = moment(
      message?.msg_date,
      "YYYY-MM-DD HH:mm:ss Z"
    ).format("YYYY-MM-DD HH:mm:ss");

    const isLessThan15DaysAgo = moment().diff(moment(messageDate), "days") < 15;
    const localDate = moment(
      message?.msg_date,
      "YYYY-MM-DD HH:mm:ss Z"
    ).local();

    // Format the local date in the desired format
    const formattedDate = localDate.format("dddd hh:mm A, D MMMM YYYY");

    const parsedDate = moment(message?.msg_date, "YYYY-MM-DD HH:mm:ss Z");

    const formattedTime = parsedDate.format("hh:mm A");
    const { msg_text, id, video_url, status } = message;

    const [live_extended_credit, liveCredit] =
      message?.msg_text?.split("\n") || [];
    const available = liveCredit?.match(/Time Available:/);

    const exactMinutes = liveCredit?.match(/(\d+) Minutes and (\d+) Seconds/)
      ? liveCredit?.match(/(\d+) Minutes and (\d+) Seconds/)
      : liveCredit?.match(/(\d+) Minutes/);

    switch (message.message_type) {
      case "live_chat_start":
        return renderLiveChatStart(formattedTime);
      case "live_chat_resume":
        return renderLiveChatResume(msg_text, message.display_message);
      case "live_chat_continue":
        return renderLiveChatContinue(
          live_extended_credit,
          available,
          exactMinutes
        );
      case "live_chat_summary":
        return renderLiveChatSummary(
          message,
          isDurationGreaterThan2Minutes,
          isLessThan15DaysAgo
        );
      case "notice":
        return renderNotice(formattedDate, msg_text, id);

      case "live_text":
        return renderText(formattedDate, msg_text, id, message);
      case "text":
        return renderText(formattedDate, msg_text, id, message);
      case "rush_text":
        return renderText(formattedDate, msg_text, id, message);
      case "info":
        return renderInfo(formattedDate, msg_text, id, message);
      case "live_photo":
        return renderPhoto(formattedDate, msg_text, id, video_url, message);
      case "rush_photo":
        return renderPhoto(formattedDate, msg_text, id, video_url, message);
      case "photo":
        return renderPhoto(formattedDate, msg_text, id, video_url, message);
      case "rush_audio":
        return renderAudio(formattedDate, video_url, id, message);
      case "audio":
        return renderAudio(formattedDate, video_url, id, message);
      case "live_chat_ended":
        return renderLiveChatEnded(msg_text);
      case "advisor_com":
        return psychicTxtAdmin(formattedDate, msg_text, id);
      default:
        return null;
    }
  };

  return <>{renderMessageContent()}</>;
};

export default ChatMessage;
