import moment from "moment";

export const generateDefaultFormData = (
  sessionID,
  activity,
  advisorIDResponse
) => {
  const advisorID = advisorIDResponse
    ? advisorIDResponse
    : JSON.parse(localStorage.getItem("advisorsData"))?.user?.id;
  const formData = new FormData();
  formData.append("session_id", sessionID); // default value for session_id
  formData.append("activity", activity); // default value for activity
  formData.append("type", "advisor");
  formData.append("extended_credits", "");
  formData.append("discount", "");
  formData.append("bonus_credits", "");
  formData.append("client_id", advisorID);
  formData.append("user_id", advisorID);
  formData.append("user_type", "advisor");
  formData.append("device_type", "web");
  return formData;
};

export const makeMessageID = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters?.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
