import React from "react";
import { useSelector } from "react-redux";

const useGetAdvisorInfo = () => {
  const { advisorLoginResponse } = useSelector((state) => state.login);

  const advisorLocalStorageInfo = JSON.parse(
    localStorage.getItem("advisorsData")
  );

  const advisorAPIResponse = advisorLoginResponse;

  const advisorInfoData = advisorAPIResponse
    ? advisorAPIResponse
    : advisorLocalStorageInfo;

  return { advisorInfoData };
};

export default useGetAdvisorInfo;
