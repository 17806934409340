import moment from "moment";

const formatTimeDifference = (timestamp) => {
  const now = moment();
  const duration = moment.duration(now.diff(moment(timestamp)));

  const minutes = Math.floor(duration.asMinutes());
  const hours = Math.floor(duration.asHours());
  const days = Math.floor(duration.asDays());

  if (minutes < 60) {
    return `${minutes}m`;
  } else if (hours < 24) {
    return `${hours}h`;
  } else {
    return `${days}d`;
  }
};

export default formatTimeDifference;
