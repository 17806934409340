import { useState, useRef, useEffect } from "react";

import useTextOrderTimer from "./useTextOrderTimer";
import { setGetReplyThisClientData } from "../features/chat/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import getUserId from "../utils/helper/getUserId";
import MicRecorder from "mic-recorder-to-mp3";
// import { convertAudio } from "audio-converter";
const recorder = new MicRecorder({
  bitRate: 128,
});

const useAudioRecorder = () => {
  const dispatch = useDispatch();
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const [recordingDuration, setRecordingDuration] = useState(0);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const wavesurferRef = useRef(null);
  const waveformContainerRef = useRef(null);
  const { userSessionData } = useSelector((state) => state.chat);
  const { advisorLoginResponse } = useSelector((state) => state.login);
  const { client_id } = userSessionData;
  const userId = getUserId(advisorLoginResponse);
  const { startAllModalHandlerTimer, stoptAllModalHandlerTimer, timerStates } =
    useTextOrderTimer();
  const startRecording = async () => {
    recorder
      .start()
      .then(() => {
        // something else
      })
      .catch((e) => {
        console.error(e);
      });
    setRecordingDuration(0);
    setIsRecording(true);
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream, {
      mimeType: "audio/webm",
    });
    mediaRecorderRef.current.start();

    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorderRef.current.onstop = () => {
      recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          // do what ever you want with buffer and blob
          // Example: Create a mp3 file and play
          const file = new File(buffer, "me-at-thevoice.mp3", {
            type: blob.type,
            lastModified: Date.now(),
          });

          const player = new Audio(URL.createObjectURL(file));
          setAudioURL(player.src);
        })
        .catch((e) => {
          alert("We could not retrieve your message");
        });

      const audioBlob = new Blob(audioChunksRef.current, {
        type: mediaRecorderRef.current.mimeType,
      });

      const audioURL = URL.createObjectURL(audioBlob);

      audioChunksRef.current = [];
      if (wavesurferRef.current) {
        wavesurferRef.current.load(audioURL);
      }
    };

    mediaRecorderRef.current.onstart = () => {
      if (mediaRecorderRef.current.state === "recording") {
        startAllModalHandlerTimer("recordingAudio", 180, () => {
          stopRecording();
        });
      } else {
      }
    };
  };

  const stopRecording = () => {
    setIsRecording(false);
    mediaRecorderRef.current.stop();
    stoptAllModalHandlerTimer("recordingAudio");
  };

  const deleteRecording = () => {
    setIsRecording(false);
    setAudioURL("");
    stoptAllModalHandlerTimer("recordingAudio");
    audioChunksRef.current = [];
    // dispatch(setGetReplyThisClientData({}));
    // if (wavesurferRef.current) {
    //   wavesurferRef.current.empty(); // Clear the waveform display
    // }
  };

  // Use convertToMP3(audioBlob) after recording stops
  // const convertAudioBlob = async (blob) => {
  //   try {
  //     const mp3Blob = await convertAudio(blob, "mp3");
  //     const mp3Url = URL.createObjectURL(mp3Blob);
  //     setConvertedAudioURL(mp3Url);
  //   } catch (error) {
  //     console.error("Error converting audio:", error);
  //   }
  // };
  // Use convertToMP3(audioBlob) after recording stops

  return {
    isRecording,
    audioURL,
    recordingDuration,
    startRecording,
    stopRecording,
    waveformContainerRef,
    deleteRecording,
    timerStates,
    setAudioURL,
    setIsRecording,
    audioChunksRef,
  };
};

export default useAudioRecorder;
