import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import Loader from "../../components/Loader";
import { setIsInsertTemplateModalOpen } from "./modalSlice";
import { BiSolidSend } from "react-icons/bi";
import { useSaveChatPayloadMutation } from "../../app/api/chat";
import useGetAdvisorInfo from "../../hooks/useGetAdvisorInfo";
import { prepareFormData } from "../../utils/helper/prepareFormData";
import {
  setIsMessageRepliedSuccessfully,
  setUserSessionData,
} from "../chat/chatSlice";
import useLogout from "../../hooks/useLogout";
import toast from "react-hot-toast";

const InsertTemplateModal = () => {
  const dispatch = useDispatch();
  const scrollBottomRef = useRef();
  const [insertTemplate, setInsertTemplate] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isInsertTemplateLoading, setIsInsertTemplateLoading] = useState(false);
  const { isInsertTemplateModalOpen } = useSelector((state) => state.modal);
  const { getFollowupTemplate } = useSelector((state) => state.chat);
  const { handleLogout } = useLogout();
  const [saveChatPayload, { error }] = useSaveChatPayloadMutation();
  const { userSessionData } = useSelector((state) => state.chat);
  const { advisorInfoData } = useGetAdvisorInfo();
  const { id = "", username = "" } = advisorInfoData?.user || {};
  const { advisor_id, advisor_displayname, client_displayname, client_id } =
    userSessionData;

  if (error) {
    if (error.status === 401) {
      handleLogout();
    }
  }
  const closeModal = () => {
    dispatch(setIsInsertTemplateModalOpen(false));
  };
  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [insertTemplate]);

  const handleTemplate = (text, index) => {
    setSelectedIndex(index);
    setInsertTemplate(text);
  };

  useEffect(() => {
    scrollToBottom();
  }, [insertTemplate]);

  const scrollToBottom = () => {
    if (scrollBottomRef.current) {
      scrollBottomRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const sendFollowUpMessage = async () => {
    if (insertTemplate.trim().length == 0) {
      toast("Please enter a message", {
        duration: 2000,
        style: { zIndex: 9999 },
      });
      return;
    }
    setIsInsertTemplateLoading(true);
    const formData = prepareFormData(
      "text",
      insertTemplate,
      advisor_id,
      advisor_displayname,
      client_id,
      client_displayname
    );

    try {
      const result = await saveChatPayload(formData);

      if (result?.data?.result == "1") {
        setInsertTemplate("");
        closeModal();
        setIsInsertTemplateLoading(false);
        dispatch(setIsMessageRepliedSuccessfully(true));
        dispatch(setUserSessionData({}));
        setSelectedIndex(null);
      }
    } catch (error) {}
  };

  return (
    <AnimatePresence>
      {isInsertTemplateModalOpen && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[999] backdrop-blur-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          //   onClick={closeModal}
        >
          <motion.div
            className="flex flex-col bg-gray-100 max-w-[50%] w-fit py-4 px-12 relative rounded-lg max-h-[60%] overflow-auto"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <h1 className="text-center text-lg font-semibold pb-2">
              Templates
            </h1>
            <div>
              <h6 className="text-center text-lg capitalize flex flex-col gap-4">
                {getFollowupTemplate?.map((item, index) => (
                  <h6
                    key={index}
                    className={`capitalize text-left text-sm font-normal border-[1px] border-solid border-gray-500 p-2 rounded-lg cursor-pointer ${
                      selectedIndex === index &&
                      "bg-[#3A86F4] border-white text-white"
                    }`}
                    onClick={() => handleTemplate(item, index)}
                  >
                    {item}
                  </h6>
                ))}
              </h6>
              <div className="flex items-center justify-between gap-4 w-full pl-3 rounded-3xl bg-white shadow-lg mt-4 p-2">
                <textarea
                  className="input w-full text-sm focus:outline-none bg-white flex-grow p-2 resize-none overflow-y-hidden rounded-3xl"
                  placeholder="Type your message ..."
                  value={insertTemplate}
                  onChange={(e) => {
                    setInsertTemplate(e.target.value);
                  }}
                  ref={textareaRef}
                  rows={1}
                  style={{ overflow: "hidden" }}
                />
                <button
                  className="bg-[#3A86F4] flex justify-center items-center p-2 rounded-full"
                  onClick={sendFollowUpMessage}
                >
                  {isInsertTemplateLoading ? (
                    <Loader width={30} height={30} color={"#fff"} />
                  ) : (
                    <BiSolidSend className="text-2xl text-white" />
                  )}
                </button>
              </div>
            </div>
            <div ref={scrollBottomRef}></div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default InsertTemplateModal;
