// slices/chatSlice.js
import { createSlice } from "@reduxjs/toolkit";

const advisorSlice = createSlice({
  name: "advisor",
  initialState: {
    advisorLiveStatus: false,
    advisorRushStatus: false,
    advisorStatus: false,
    getAdvisorLiveRate: null,
    getadvisorSelectedRate: null,
  },
  reducers: {
    setAdvisorLiveStatus: (state, action) => {
      state.advisorLiveStatus = action.payload;
    },
    setAdvisorRushStatus: (state, action) => {
      state.advisorRushStatus = action.payload;
    },
    setAdvisorStatus: (state, action) => {
      state.advisorStatus = action.payload;
    },
    setGetAdvisorLiveRate: (state, action) => {
      state.getAdvisorLiveRate = action.payload;
    },
    setGetadvisorSelectedRate: (state, action) => {
      state.getadvisorSelectedRate = action.payload;
    },
  },
});

export const {
  setAdvisorLiveStatus,
  setAdvisorRushStatus,
  setAdvisorStatus,
  setGetAdvisorLiveRate,
  setGetadvisorSelectedRate,
} = advisorSlice.actions;

export default advisorSlice.reducer;
