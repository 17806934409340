import React from "react";
import { useSelector } from "react-redux";
const Timer = () => {
  const { sessionTimer } = useSelector((state) => state.chat);
  return (
    <section className="flex items-center gap-2 p-1 border bg-[#3A86F4] text-white rounded-md w-fit h-fit">
      <div className="w-2 h-2 bg-white  border border-white rounded-full"></div>
      <h6 className="bg-[#3A86F4] text-white text-sm font-medium capitalize">
        {Math.floor(sessionTimer / 60) < 10
          ? "0" + Math.floor(sessionTimer / 60)
          : Math.floor(sessionTimer / 60)}{" "}
        :{" "}
        {sessionTimer - Math.floor(sessionTimer / 60) * 60 < 10
          ? "0" + (sessionTimer - Math.floor(sessionTimer / 60) * 60)
          : sessionTimer - Math.floor(sessionTimer / 60) * 60}{" "}
        Min
      </h6>
    </section>
  );
};

export default Timer;
