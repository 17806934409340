import moment from "moment";
import { getRemainingTime } from "./getRemainingTime";

// Function to generate message ID
const makeMessageID = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

// Function to prepare FormData
export const prepareFormData = (
  messageType,
  message,
  id,
  username,
  sender_id,
  sender_displayname,
  message_review_id,
  msg_date
) => {
  const formData = new FormData();
  formData.append("message_type", messageType);
  formData.append("msg_date", moment().format("YYYY-MM-DD HH:mm:ss Z"));
  formData.append("receiver_displayname", sender_displayname);
  formData.append("receiver_id", sender_id);
  formData.append("client_dob", "");
  formData.append("receiver_type", "client");
  formData.append("msg_text", message);
  formData.append("sender_displayname", username);
  formData.append(
    "email_notification_enabled",
    messageType === "text" ? "0" : "1"
  );
  formData.append("sender_id", id);
  formData.append("sender_type", "advisor");
  formData.append("status", messageType === "info" ? "0" : "1");
  formData.append("review_status", "0");
  formData.append("video_url", "");
  formData.append("message_review_id", "w_".concat(makeMessageID(21)));
  formData.append(
    "client_message_review_id",
    message_review_id ? message_review_id : ""
  );
  formData.append(
    "first_message_id",
    message_review_id ? message_review_id : ""
  );
  formData.append("order_type", messageType === "text" ? "text" : "");
  formData.append(
    "response_time",
    msg_date ? getRemainingTime(msg_date) : "followup"
  );
  formData.append("user_id", id);
  formData.append("user_type", "advisor");
  formData.append("device_type", "web");
  formData.append("re_engagement", "0");

  return formData;
};
// Function to prepare FormData
export const sendFormData = (
  messageType,
  message,
  id,
  username,
  sender_id,
  sender_displayname
) => {
  const formData = new FormData();
  formData.append("message_type", "text");
  formData.append("msg_date", moment().format("YYYY-MM-DD HH:mm:ss Z"));
  formData.append("receiver_displayname", sender_displayname);
  formData.append("receiver_id", sender_id);
  formData.append("client_dob", "");
  formData.append("receiver_type", "client");
  formData.append("msg_text", message);
  formData.append("sender_displayname", username);
  formData.append("email_notification_enabled", "1");
  formData.append("sender_id", id);
  formData.append("sender_type", "advisor");
  formData.append("status", "1");
  formData.append("review_status", "0");
  formData.append("video_url", "");
  formData.append("message_review_id", "w_".concat(makeMessageID(21)));
  formData.append("client_message_review_id", "");
  formData.append("first_message_id", "");
  formData.append("response_time", "0");
  formData.append("user_id", id);
  formData.append("user_type", "advisor");
  formData.append("device_type", "web");
  formData.append("re_engagement", "1");

  return formData;
};
