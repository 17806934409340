import React from "react";
import { useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import Loader from "../../components/Loader";

const WaitingForRecharge = () => {
  const { isWaitingForClientRecharge, clientResponseFromUpdateChatRoom } =
    useSelector((state) => state.modal);
  return (
    <AnimatePresence>
      {isWaitingForClientRecharge && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[99999999999] backdrop-blur-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="flex flex-col bg-white min-w-80 w-fit py-8 px-12 relative rounded-lg"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="flex items-center gap-4">
              <h6 className="text-center text-lg capitalize">
                {clientResponseFromUpdateChatRoom}
              </h6>
              <Loader width={50} height={50} color={"#3A86F4"} />
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default WaitingForRecharge;
