import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
const jwtToken = JSON.parse(localStorage.getItem("advisorsData"))?.jwt;
const ProtectedRoute = ({ children }) => {
  const { advisorJWTToken } = useSelector((state) => state.login);
  let location = useLocation();
  if (advisorJWTToken || jwtToken) {
    return children;
  }
  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default ProtectedRoute;
