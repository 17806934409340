import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";

import { setIsTemplateModalOpen } from "./modalSlice";
import {
  setAdvisorMessage,
  setAdvisorReEngagementMessage,
  setAdvisorTxtOrderMessage,
} from "../chat/chatSlice";

const TemplateModal = () => {
  const dispatch = useDispatch();
  const { isTemplateModalOpen } = useSelector((state) => state.modal);
  const {
    getTextTemplate,
    getLiveTemplate,
    userSessionData,
    isReEngagementClicked,
  } = useSelector((state) => state.chat);
  const { session_status } = userSessionData;
  const closeModal = () => {
    dispatch(setIsTemplateModalOpen(false));
  };

  const dataToMap = session_status === "S" ? getLiveTemplate : getTextTemplate;

  const handleTemplate = (text) => {
    session_status === "S"
      ? dispatch(setAdvisorMessage(text))
      : isReEngagementClicked
      ? dispatch(setAdvisorReEngagementMessage(text))
      : dispatch(setAdvisorTxtOrderMessage(text));
    closeModal();
  };

  return (
    <AnimatePresence>
      {isTemplateModalOpen && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[99999999999] backdrop-blur-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={closeModal}
        >
          <motion.div
            className="flex flex-col bg-white max-w-[50%] w-fit py-4 px-12 relative rounded-lg max-h-[60%] overflow-auto"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <h1 className="text-center text-lg font-semibold pb-2">
              Templates
            </h1>
            <div>
              <h6 className="text-center text-lg capitalize flex flex-col gap-4">
                {dataToMap.map((item, index) => (
                  <h6
                    key={index}
                    className="capitalize text-left text-sm font-normal border-[1px] border-solid border-gray-500 p-2 rounded-lg cursor-pointer"
                    onClick={() => handleTemplate(item)}
                  >
                    {item}
                  </h6>
                ))}
              </h6>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default TemplateModal;
