import { useState, useEffect, useCallback } from "react";
import { usePubNub } from "pubnub-react";
import { useSelector } from "react-redux";

const useTypingStatus = (channels) => {
  const pubnub = usePubNub();
  const [getLiveChatSession, setGetLiveChatSession] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const { advisorMessage } = useSelector((state) => state.chat);

  const updatePresenceState = useCallback(
    (typingStatus) => {
      if (getLiveChatSession) {
        pubnub.setState(
          {
            state: { type: "advisor", isTyping: typingStatus },
            channels: [getLiveChatSession],
          },
          (status) => {
            if (status.error) {
              console.error("PubNub Error:", status.errorData);
            }
          }
        );
      }
    },
    [pubnub, getLiveChatSession]
  );

  useEffect(() => {
    const typing = advisorMessage.trim().length > 0;

    if (typing !== isTyping) {
      setIsTyping(typing);
      updatePresenceState(typing);
    }
  }, [advisorMessage, isTyping, updatePresenceState]);

  return { setGetLiveChatSession };
};

export default useTypingStatus;
