export const colorClasses = {
  A: "bg-gradient-to-br from-blue-500 to-blue-70",
  B: "bg-gradient-to-br from-green-500 to-green-700",
  C: "bg-gradient-to-br from-yellow-500 to-yellow-700",
  D: "bg-gradient-to-br from-purple-500 to-purple-700",
  E: "bg-gradient-to-br from-red-500 to-red-700",
  F: "bg-gradient-to-br from-green-500 to-green-700",
  G: "bg-gradient-to-br from-indigo-500 to-indigo-700",
  H: "bg-gradient-to-br from-blue-500 to-blue-700",
  I: "bg-gradient-to-br from-pink-500 to-pink-700",
  J: "bg-gradient-to-br from-red-500 to-red-700",
  K: "bg-gradient-to-br from-indigo-500 to-indigo-700",
  L: "bg-gradient-to-br from-purple-500 to-purple-700",
  M: "bg-gradient-to-br from-pink-500 to-pink-700",
  N: "bg-gradient-to-br from-blue-500 to-blue-70",
  O: "bg-gradient-to-br from-red-500 to-red-700",
  P: "bg-gradient-to-br from-yellow-500 to-yellow-700",
  Q: "bg-gradient-to-br from-green-500 to-green-700",
  R: "bg-gradient-to-br from-pink-500 to-pink-700",
  S: "bg-gradient-to-br from-indigo-500 to-indigo-700",
  T: "bg-gradient-to-br from-yellow-500 to-yellow-700",
  U: "bg-gradient-to-br from-pink-500 to-pink-700",
  V: "bg-gradient-to-br from-red-500 to-red-700",
  W: "bg-gradient-to-br from-pink-500 to-pink-700",
  X: "bg-gradient-to-br from-purple-500 to-purple-700",
  Y: "bg-gradient-to-br from-blue-500 to-blue-70",
  Z: "bg-gradient-to-br from-indigo-500 to-indigo-700",
};
