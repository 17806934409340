// slices/chatSlice.js
import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    isCallingModalOpen: false,
    isClientResponseModalOpen: false,
    isWaitingForMessageOpen: false,
    isWaitingForClientRecharge: false,
    isLiveChatEnded: false,
    isPictureModalOpen: false,
    isErrorModalOpen: false,
    isInfoModalOpen: false,
    isTemplateModalOpen: false,
    isInsertTemplateModalOpen: false,
    isAcknowledgeModalOpen: false,
    isAcknowledgedModalOpen: false,
    isRefundModalOpen: false,
    isLiveChatAIResponseOpen: false,
    isLiveChatAIResponseData: null,
    errorModalMessage: "",
    clientResponseFromUpdateChatRoom: "",
    selectedFile: {
      file: null,
      type: "",
    },
  },
  reducers: {
    setIsCallingModalOpen: (state, action) => {
      state.isCallingModalOpen = action.payload;
    },
    setIsClientResponseModalOpen: (state, action) => {
      state.isClientResponseModalOpen = action.payload;
    },
    setClientResponseFromUpdateChatRoom: (state, action) => {
      state.clientResponseFromUpdateChatRoom = action.payload;
    },
    setIsWaitingForMessageOpen: (state, action) => {
      state.isWaitingForMessageOpen = action.payload;
    },
    setIsWaitingForClientRecharge: (state, action) => {
      state.isWaitingForClientRecharge = action.payload;
    },
    setIsLiveChatEnded: (state, action) => {
      state.isLiveChatEnded = action.payload;
    },
    setIsPictureModalOpen: (state, action) => {
      state.isPictureModalOpen = action.payload;
    },
    setSelectedFile: (state, action) => {
      state.selectedFile = action.payload;
    },
    setIsErrorModalOpen: (state, action) => {
      state.isErrorModalOpen = action.payload;
    },
    setErrorModalMessage: (state, action) => {
      state.errorModalMessage = action.payload;
    },
    setIsInfoModalOpen: (state, action) => {
      state.isInfoModalOpen = action.payload;
    },
    setIsTemplateModalOpen: (state, action) => {
      state.isTemplateModalOpen = action.payload;
    },
    setIsInsertTemplateModalOpen: (state, action) => {
      state.isInsertTemplateModalOpen = action.payload;
    },
    setIsAcknowledgeModalOpen: (state, action) => {
      state.isAcknowledgeModalOpen = action.payload;
    },
    setIsAcknowledgedModalOpen: (state, action) => {
      state.isAcknowledgedModalOpen = action.payload;
    },
    setIsRefundModalOpen: (state, action) => {
      state.isRefundModalOpen = action.payload;
    },
    setIsLiveChatAIResponseData: (state, action) => {
      state.isLiveChatAIResponseData = action.payload;
    },
    setIsLiveChatAIResponseOpen: (state, action) => {
      state.isLiveChatAIResponseOpen = action.payload;
    },
  },
});

export const {
  setIsCallingModalOpen,
  setIsClientResponseModalOpen,
  setClientResponseFromUpdateChatRoom,
  setIsWaitingForMessageOpen,
  setIsWaitingForClientRecharge,
  setIsLiveChatEnded,
  setIsPictureModalOpen,
  setSelectedFile,
  setIsErrorModalOpen,
  setErrorModalMessage,
  setIsInfoModalOpen,
  setIsTemplateModalOpen,
  setIsInsertTemplateModalOpen,
  setIsAcknowledgeModalOpen,
  setIsAcknowledgedModalOpen,
  setIsRefundModalOpen,
  setIsLiveChatAIResponseOpen,
  setIsLiveChatAIResponseData,
} = modalSlice.actions;

export default modalSlice.reducer;
