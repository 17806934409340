import React, { useEffect, useState } from "react";
import getFirstLetter from "../utils/helper/getFirstLetter";
import {
  setChatHeadSelectedUser,
  setGetReplyThisClientData,
  setIsReplyThisClient,
} from "../features/chat/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import getTimeDifferenceFromUtc from "../utils/helper/getTimeDifferenceFromUtc";
import useTextOrderTimer from "../hooks/useTextOrderTimer";
import OrderTimer from "./OrderTimer";
import { motion } from "framer-motion";
import {
  setIsAcknowledgedModalOpen,
  setIsAcknowledgeModalOpen,
  setIsRefundModalOpen,
} from "../features/modal/modalSlice";
import Loader from "./Loader";
import { colorClasses } from "../utils/helper/colorClasses";

const ConversationItem = ({
  active,
  time,
  name,
  message,
  advisorProfile,
  id,
}) => {
  const [pendingCount, setPendingCount] = useState(
    advisorProfile?.pending_messages_count > 0
      ? advisorProfile?.pending_messages_count
      : 0
  );
  const colorClass = colorClasses[getFirstLetter(name)] || "bg-gray-500";
  const { startAllModalHandlerTimer, stoptAllModalHandlerTimer, timerStates } =
    useTextOrderTimer();
  const dispatch = useDispatch();
  const {
    chatHeadSelectedUser,
    userSessionData,
    chatBodyLiveFirebaseData,
    getPubnubMessages,
    isAdminStatusOk,
    isTyingClient,
  } = useSelector((state) => state.chat);
  const { session_status, client_displayname } = userSessionData;

  const waveAnimation = {
    animate: {
      y: [0, -5, 0],
      opacity: [1, 0.5, 1],
    },
    transition: {
      duration: 1,
      repeat: Infinity,
      ease: "easeInOut",
    },
  };

  const filterfirebaseMessage = chatBodyLiveFirebaseData.filter(
    (msg) => msg.message_type === "live_text"
  );
  useEffect(() => {
    if (advisorProfile?.pn_gcm?.data?.badge === "1") {
      setPendingCount((prevCount) => prevCount + 1); // Increase pending count by 1
    }
  }, [getPubnubMessages]);

  function getRemainingTime(dateString, messageType) {
    const targetDuration =
      messageType === "text"
        ? 16 * 60 * 60 * 1000 // 16 hours in milliseconds
        : messageType === "rush_text" || messageType === "rush"
        ? 1 * 60 * 60 * 1000 // 1 hour in milliseconds
        : 0;

    const date = new Date(dateString);
    const currentTime = new Date();
    const timeDifference =
      date.getTime() + targetDuration - currentTime.getTime();

    return timeDifference;
  }

  const parseDate = (dateString) => {
    // Return a valid Date object or null if the date string is invalid
    if (!dateString || isNaN(Date.parse(dateString))) {
      return null;
    }
    return new Date(dateString);
  };

  function getMinRemainingTimeMessageType(advisorProfile) {
    let minRemainingTime = Infinity;
    let bestMessageType = null;
    let bestMessageId = null;
    let bestMessageDate = null;

    // Check pending messages (if any)
    if (advisorProfile?.pending_messages_count > 0) {
      const pendingTime = getRemainingTime(
        advisorProfile.pending_timer_date,
        advisorProfile.timer_type
      );
      if (pendingTime < minRemainingTime) {
        minRemainingTime = pendingTime;
        bestMessageType = advisorProfile.timer_type;
        bestMessageId = advisorProfile.id;
        bestMessageDate = parseDate(advisorProfile.pending_timer_date);
      }
    }

    // Check messages array
    if (advisorProfile?.messages) {
      advisorProfile.messages.forEach((message) => {
        const messageTime = getRemainingTime(
          message.msg_date,
          message.message_type
        );
        if (messageTime < minRemainingTime) {
          minRemainingTime = messageTime;
          bestMessageType = message.message_type;
          bestMessageId = message.message_id;

          bestMessageDate = parseDate(message.msg_date);
        }
      });
    }

    return { bestMessageId, bestMessageType, bestMessageDate };
  }
  const { bestMessageId, bestMessageType, bestMessageDate } =
    getMinRemainingTimeMessageType(advisorProfile);

  useEffect(() => {
    if (bestMessageDate) {
      // Convert baseDate to UTC
      const utcPendingTimerDate = new Date(bestMessageDate.toUTCString());

      const duration =
        bestMessageType === "text" ||
        bestMessageType === "photo" ||
        bestMessageType === "audio"
          ? 16 * 60 * 60 * 1000 // 16 hours in milliseconds
          : bestMessageType === "rush_text" ||
            bestMessageType === "rush" ||
            bestMessageType === "rush_photo" ||
            bestMessageType === "rush_audio"
          ? 1 * 60 * 60 * 1000
          : ""; // 1 hour in milliseconds

      // Add the duration to the UTC pending timer date
      const targetEndDate = new Date(utcPendingTimerDate.getTime() + duration);
      // Get the current UTC time
      const now = new Date();
      const utcNow = new Date(now.toUTCString());

      // Calculate the remaining time in seconds
      const remainingTime = Math.max(0, (targetEndDate - utcNow) / 1000); // Convert milliseconds to seconds
      if (remainingTime > 0) {
        startAllModalHandlerTimer(bestMessageId, remainingTime, () => {});
      } else {
        stoptAllModalHandlerTimer(bestMessageId);
      }
    }
  }, [advisorProfile]);

  return (
    <div
      onClick={() => {
        if (session_status !== "S") {
          if (isAdminStatusOk) {
            if (
              advisorProfile?.message_type === "advisor_com" &&
              advisorProfile?.status === "0"
            ) {
              dispatch(setChatHeadSelectedUser(advisorProfile));
              dispatch(setIsAcknowledgedModalOpen(true));
            } else {
              dispatch(setIsAcknowledgeModalOpen(true));
            }
          } else {
            dispatch(setChatHeadSelectedUser(advisorProfile));
            dispatch(setIsReplyThisClient(false));
            dispatch(setGetReplyThisClientData({}));
          }
        }
      }}
      className={`${
        chatHeadSelectedUser?.id == id || session_status === "S"
          ? `bg-gray-100 border-r-4 border-r-[#3A86F4]`
          : ""
      }`}
    >
      <div className={"conversation-item p-1  m-1 rounded-md py-2"}>
        <div
          className={`flex items-center  ${
            session_status === "S" ? "" : "cursor-pointer"
          }`}
        >
          <div
            className={`w-10 h-10 m-1 text-2xl flex justify-center items-center rounded-full text-white shadow-md ${colorClass}`}
          >
            {getFirstLetter(name)}
          </div>
          <div className="flex justify-between flex-grow p-2">
            <div className="flex text-md ">
              <div className="text-sm font-medium text-gray-700 capitalize flex flex-col">
                {name}
                {isTyingClient && session_status === "S" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="mr-1 text-xs font-light text-green-600 lowercase">{`typing`}</div>
                    <motion.div
                      {...waveAnimation}
                      transition={{ ...waveAnimation.transition, delay: 0 }}
                      className="mr-1 text-xs font-light text-green-600"
                    >
                      .
                    </motion.div>
                    <motion.div
                      {...waveAnimation}
                      transition={{ ...waveAnimation.transition, delay: 0.2 }}
                      className="mr-1 text-xs font-light text-green-600"
                    >
                      .
                    </motion.div>
                    <motion.div
                      {...waveAnimation}
                      transition={{ ...waveAnimation.transition, delay: 0.4 }}
                      className="mr-1 text-xs font-light text-green-600"
                    >
                      .
                    </motion.div>
                  </div>
                )}
                <span className="text-xs font-light">
                  {advisorProfile?.messages
                    ? advisorProfile?.messages[
                        advisorProfile?.messages.length - 1
                      ]?.msg_text.slice(0, 20) +
                      (advisorProfile?.messages[
                        advisorProfile?.messages.length - 1
                      ]?.msg_text.length > 20
                        ? "..."
                        : "")
                    : message.slice(0, 20) + (message.length > 20 ? "..." : "")}
                </span>
              </div>
            </div>
            <div className="text-gray-400 capitalize text-xs font-medium flex flex-col items-center">
              <h6>
                {(advisorProfile?.pending_messages_count > 0 ||
                  advisorProfile?.messages) &&
                (bestMessageType === "rush_text" ||
                  bestMessageType === "rush" ||
                  bestMessageType === "rush_photo" ||
                  bestMessageType === "rush_audio")
                  ? "Faast Pending"
                  : bestMessageType === "text" ||
                    bestMessageType === "audio" ||
                    bestMessageType === "photo"
                  ? "Pending"
                  : ""}{" "}
                {(advisorProfile?.pending_messages_count > 0 ||
                  advisorProfile?.messages) &&
                  `(${
                    +advisorProfile?.pending_messages_count +
                    (+advisorProfile?.messages?.length || 0)
                  })`}
              </h6>
              {advisorProfile?.message_type === "advisor_com" &&
                advisorProfile?.status === "0" && (
                  <h6 className="text-gray-400 capitalize text-xs font-medium">
                    Pending
                  </h6>
                )}

              {(advisorProfile?.pending_messages_count > 0 ||
                advisorProfile?.messages) && (
                <OrderTimer remainingTime={timerStates[bestMessageId] || 0} />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex-grow border-t bg-gray-100 opacity-50 my-2"></div> */}
    </div>
  );
};

export default ConversationItem;
