// Timer.js
import React from "react";

const OrderTimer = ({ remainingTime }) => {
  // Calculate hours, minutes, and seconds
  const hours = Math.floor(remainingTime / 3600);
  const minutes = Math.floor((remainingTime % 3600) / 60);
  const seconds = Math.floor(remainingTime % 60);

  // Format the time as hh:mm:ss
  const formattedTime = [
    hours.toString().padStart(2, "0"),
    minutes.toString().padStart(2, "0"),
    seconds.toString().padStart(2, "0"),
  ].join(":");

  return (
    <div className="text-sm font-medium text-gray-700">{formattedTime}</div>
  );
};

export default OrderTimer;
