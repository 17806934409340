import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  setIsCallingModalOpen,
  setIsWaitingForMessageOpen,
} from "../features/modal/modalSlice";

const createWorker = (workerScript) => {
  const blob = new Blob([workerScript], { type: "application/javascript" });
  const url = URL.createObjectURL(blob);
  return new Worker(url);
};

const timerWorkerScript = `
  let liveSeconds;
  let isRunning = false;
  self.onmessage = function(e) {
    if (typeof e.data === 'number') {
      liveSeconds = e.data;
    } else if (e.data === 'start' && !isRunning) {
      isRunning = true;
      if (typeof liveSeconds !== 'undefined') {
        self.interval = setInterval(() => {
          liveSeconds -= 1;
          self.postMessage({ type: 'timer', value: liveSeconds });
          if (liveSeconds <= 0) {
            self.postMessage({ type: 'timer', value: 0 });
            clearInterval(self.interval);
            isRunning = false;
          }
        }, 1000);
      }
    } else if (e.data === 'stop') {
      clearInterval(self.interval);
      isRunning = false;
    }
  };
`;

const useAllModalTimerHandler = () => {
  const dispatch = useDispatch();
  const timerWorkersRef = useRef({});

  useEffect(() => {
    return () => {
      Object.values(timerWorkersRef.current).forEach((worker) =>
        worker.terminate()
      );
    };
  }, []);

  const startAllModalHandlerTimer = (timerId, duration, onTimeout) => {
    if (timerWorkersRef.current[timerId]) {
      timerWorkersRef.current[timerId].terminate();
    }

    const worker = createWorker(timerWorkerScript);
    worker.onmessage = (e) => {
      const { type, value } = e.data;
      if (type === "timer" && value === 0) {
        onTimeout();
        delete timerWorkersRef.current[timerId];
      }
    };

    timerWorkersRef.current[timerId] = worker;
    worker.postMessage(duration);
    worker.postMessage("start");
  };

  const stoptAllModalHandlerTimer = (timerId) => {
    if (timerWorkersRef.current[timerId]) {
      timerWorkersRef.current[timerId].postMessage("stop");
      timerWorkersRef.current[timerId].terminate();
      delete timerWorkersRef.current[timerId];
    }
  };

  return { startAllModalHandlerTimer, stoptAllModalHandlerTimer };
};

export default useAllModalTimerHandler;
