import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { setIsErrorModalOpen } from "./modalSlice";

const ErrorHandlingModal = () => {
  const dispatch = useDispatch();
  const { isErrorModalOpen, errorModalMessage } = useSelector(
    (state) => state.modal
  );
  const closeModal = () => {
    dispatch(setIsErrorModalOpen(false));
  };

  return (
    <AnimatePresence>
      {isErrorModalOpen && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[99999999999] backdrop-blur-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={closeModal}
        >
          <motion.div
            className="flex flex-col bg-white min-w-80 max-w-[70%] w-fit py-4 px-12 relative rounded-lg"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div>
              <h6 className="text-center text-lg capitalize">
                {errorModalMessage}
              </h6>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ErrorHandlingModal;
