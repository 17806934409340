import React from "react";
const Loader = ({ width, height, color }) => {
  return (
    <div
      style={{
        width: width,
        height: height,
        borderRadius: "50%",
        background: `radial-gradient(farthest-side, ${color} 94%, #0000) top/8px 8px no-repeat, conic-gradient(#0000 30%, ${color})`,
        WebkitMask: `radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0)`,
        animation: "s3 1s infinite linear",
      }}
    ></div>
  );
};

export default Loader;
