const advisorID = JSON.parse(localStorage.getItem("advisorsData"));

const getUserId = (advisorLoginResponse) => {
  return advisorLoginResponse
    ? advisorLoginResponse?.user?.id
    : advisorID?.user?.id;
};

export default getUserId;

export const getUserData = (advisorLoginResponse) => {
  return advisorLoginResponse ? advisorLoginResponse?.user : advisorID?.user;
};
