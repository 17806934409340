import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import getClientName from "../utils/helper/getClientName";
import getFirstLetter from "../utils/helper/getFirstLetter";
import { motion, useAnimation } from "framer-motion";
import {
  useCreateMessageMutation,
  useGetAdvisorChatBodyMutation,
  useSaveChatPayloadMutation,
  useUpdateChatRoomMutation,
} from "../app/api/chat";
import getUserId from "../utils/helper/getUserId";
import ChatMessage from "./ChatMessage";
import { BiSolidSend } from "react-icons/bi";
import { IoNewspaperOutline } from "react-icons/io5";
import AIIcon from "../assets/AI-default.51bec1607029ffbd.svg";
import {
  addEmojiToMessage,
  setAdvisorMessage,
  setAdvisorReEngagementMessage,
  setAdvisorTxtOrderMessage,
  setChatBodyLiveFilteredData,
  setIsInfoMessageToShow,
  setIsLoadMoreMessageLoading,
  setIsMessageLoading,
  setIsReEngagementRepliedSuccessfully,
  setIsSendingMessageIsSuccessfully,
  setLiveChatImageUrl,
  setLoadMoreMessages,
  setNoMoreDataToShow,
} from "../features/chat/chatSlice";
import {
  setIsInfoModalOpen,
  setIsLiveChatAIResponseOpen,
  setIsTemplateModalOpen,
} from "../features/modal/modalSlice";
import Loader from "./Loader";
import { useSendMessage } from "../hooks/useSendMessage";
import { MdDelete } from "react-icons/md";

import { CiMicrophoneOn } from "react-icons/ci";
import Timer from "./Timer";
import { generateDefaultFormData } from "../utils/helper/getUpdateRoomData";
import EmojiPicker from "emoji-picker-react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiMessageDetail } from "react-icons/bi";
import { RiImageAddLine } from "react-icons/ri";
import { setIsPictureModalOpen } from "../features/modal/modalSlice";
import AdvisorStatus from "./AdvisorStatus";
import useLogout from "../hooks/useLogout";
import { getRemainingTime } from "../utils/helper/getRemainingTime";
import { IoMicSharp } from "react-icons/io5";
import AWS from "aws-sdk";
import useAudioRecorder from "../hooks/useAudioRecorder";
import OrderTimer from "./OrderTimer";
import { sendFormData } from "../utils/helper/prepareFormData";
import useGetAdvisorInfo from "../hooks/useGetAdvisorInfo";
import toast from "react-hot-toast";
import { formatDateForFilename } from "../utils/helper/formatDate";
import { colorClasses } from "../utils/helper/colorClasses";
// Initialize AWS S3
const s3 = new AWS.S3({
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYIDAWS,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEYAWS,
});

const advisorID = JSON.parse(localStorage.getItem("advisorsData"));

const Messages = () => {
  const { handleLogout } = useLogout();
  const [updateChatRoom, { error: isError }] = useUpdateChatRoomMutation();
  const messageContainerRef = useRef(null);
  const audioRef = useRef(null);
  const dispatch = useDispatch();
  const [isRenderedMessageLoading, setIsRenderedMessageLoading] =
    useState(true);
  const { advisorInfoData } = useGetAdvisorInfo();
  const { id = "", username = "" } = advisorInfoData?.user || {};
  const [saveChatPayload] = useSaveChatPayloadMutation();
  const [createMessage] = useCreateMessageMutation();
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  const [isReEngagementLoading, setIsReEngagementLoading] = useState(false);
  const { handleSendMessagetoClient } = useSendMessage();
  const {
    chatHeadSelectedUser,
    chatBodyLiveFilteredData,
    loadMoreMessages,
    noMoreDataToShow,
    isMessageLoading,
    isLoadMoreMessageLoading,
    userSessionData,
    chatBodyLiveFirebaseData,
    advisorMessage,
    freeTimer,
    paidTimer,
    sessionTimer,
    getPubnubMessages,
    isReplyThisClient,
    advisorTxtOrderMessage,
    getReplyThisClientData,
    isMessageRepliedSuccessfully,
    isInfoMessageToShow,
    getPubnubMessageInfo,
    isInboxIsClicked,
    isReEngagementClicked,
    advisorReEngagementMessage,
    isReEngagementRepliedSuccessfully,
    isSendingMessageIsSuccessfully,
    isTyingClient,
  } = useSelector((state) => state.chat);
  const {
    isRecording,
    audioURL,
    recordingDuration,
    startRecording,
    stopRecording,
    deleteRecording,
    timerStates,
    setAudioURL,
    setIsRecording,
    audioChunksRef,
  } = useAudioRecorder();

  const { client_id, session_status, client_displayname } = userSessionData;
  const { advisorLoginResponse } = useSelector((state) => state.login);
  const [getAdvisorChatBody, { error }] = useGetAdvisorChatBodyMutation();
  const userId = getUserId(advisorLoginResponse);

  const colorClass =
    colorClasses[
      session_status === "A" || session_status === "S" || session_status === "X"
        ? getFirstLetter(client_displayname)
        : getFirstLetter(
            getClientName(
              chatHeadSelectedUser?.sender_id,
              chatHeadSelectedUser?.receiver_displayname,
              chatHeadSelectedUser?.sender_displayname
            )
          )
    ] || "bg-gray-500";

  const clientId =
    session_status === "A" || session_status === "S" || session_status === "X"
      ? client_id
      : chatHeadSelectedUser?.sender_id == userId
      ? chatHeadSelectedUser?.receiver_id
      : chatHeadSelectedUser?.sender_id;

  const getChatBodyResponse = async () => {
    if (loadMoreMessages === 0) {
      dispatch(setIsMessageLoading(true));
    } else {
      dispatch(setIsLoadMoreMessageLoading(true));
    }

    const formData = new FormData();
    formData.append("user_a", userId);
    formData.append("user_b", clientId);
    formData.append("page", loadMoreMessages);
    formData.append("type", "advisor");
    formData.append("user_id", userId);
    formData.append("user_type", "advisor");

    const response = await getAdvisorChatBody(formData);
    dispatch(setIsMessageLoading(false));
    dispatch(setIsLoadMoreMessageLoading(false));
    setIsRenderedMessageLoading(false);

    const infoMessageChecking = response?.data?.message;

    if (
      infoMessageChecking?.some(
        (message) => message.status === "0" && message.sender_type === "client"
      )
    ) {
      dispatch(setIsInfoMessageToShow(true));
    } else {
      dispatch(setIsInfoMessageToShow(false));
    }
    if (response?.data?.message?.length !== 0) {
      dispatch(setChatBodyLiveFilteredData(response?.data?.message));
    } else if (response?.data?.message?.length === 0) {
      dispatch(setNoMoreDataToShow(true));
    } else if (response?.error?.status === 401) {
      dispatch(setChatBodyLiveFilteredData([]));
    }
  };

  useEffect(() => {
    if (clientId) {
      getChatBodyResponse();
    } else {
      setIsRenderedMessageLoading(true);
    }
  }, [clientId, loadMoreMessages]);
  useEffect(() => {
    if (session_status === "A" && clientId) {
      getChatBodyResponse();
    }
  }, []);

  useEffect(() => {
    if (isMessageRepliedSuccessfully || isReEngagementRepliedSuccessfully)
      getChatBodyResponse();
  }, [isMessageRepliedSuccessfully, isReEngagementRepliedSuccessfully]);

  // Display loading message while fetching data
  // if (isLoading) {
  //   return <p>Loading...</p>;
  // }

  const sortMessages = (a, b) => {
    const pubnubId = getPubnubMessageInfo?.id;

    // Check if either a or b matches the pubnubId
    if (a.id === pubnubId && b.id !== pubnubId) {
      return 1; // a should be above b
    }
    if (a.id !== pubnubId && b.id === pubnubId) {
      return -1; // a should be below b
    }
    // Check status first
    if (a.status === "0" && b.status !== "0") {
      return 1; // a should be below b
    }
    if (a.status !== "0" && b.status === "0") {
      return -1; // a should be above b
    }

    // If statuses are the same, sort by date
    const dateA = new Date(a.msg_date).getTime();
    const dateB = new Date(b.msg_date).getTime();

    if (dateA === dateB) {
      // If dates are the same, sort by id
      return a.id < b.id ? -1 : 1;
    }

    return dateA < dateB ? -1 : 1;
  };

  const handleLoadMoreMessages = () => {
    dispatch(setLoadMoreMessages(loadMoreMessages + 1));
  };

  const scrollToBottom = useCallback(() => {
    if (loadMoreMessages == 0) {
      if (messageContainerRef.current) {
        messageContainerRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [loadMoreMessages]);

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollToBottom();
    }, 100); // Delay to ensure DOM is updated

    return () => clearTimeout(timer);
  }, [
    advisorMessage,
    chatBodyLiveFirebaseData,
    getPubnubMessages,
    getPubnubMessageInfo,
    advisorTxtOrderMessage,
    chatBodyLiveFilteredData,
    loadMoreMessages,
  ]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        // If Shift + Enter is pressed, allow a new line
        return;
      }
      e.preventDefault(); // Prevent new line
      if (
        advisorMessage?.trim()?.length > 0 &&
        userSessionData?.session_status === "S"
      ) {
        handleSendMessagetoClient();
        dispatch(setAdvisorMessage(""));
      } else if (userSessionData?.session_status !== "S") {
        if (
          getReplyThisClientData?.response_time === "rush_audio" ||
          getReplyThisClientData?.response_time === "audio"
        ) {
          dispatch(setIsSendingMessageIsSuccessfully(true));
          handleAudioSendMessage();
        } else if (isReEngagementClicked) {
          handleReEngagementSendMessagetoClient();
        } else {
          if (
            (isReplyThisClient || chatHeadSelectedUser) &&
            session_status !== "S"
          ) {
            dispatch(setIsSendingMessageIsSuccessfully(true));
          }
          handleSendMessagetoClient();
          dispatch(setAdvisorMessage(""));
        }
      }
    }
  };

  const handleChatResponse = async (res) => {
    const formData = generateDefaultFormData(
      userSessionData?.session_id,
      "live_chat_end",
      advisorLoginResponse?.user?.id
    );
    const response = await updateChatRoom(formData);
  };

  const addEmoji = (e) => {
    let emoji = e.emoji;
    dispatch(addEmojiToMessage(emoji));
    setIsEmojiOpen(false);
  };

  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [advisorTxtOrderMessage, advisorMessage, handleKeyDown]);

  const pulseAnimation = {
    initial: { scale: 1 },
    animate: {
      scale: [1, 2, 1],
      transition: {
        duration: 1,
        repeat: Infinity,
        repeatType: "loop",
      },
    },
  };

  const handleSendMessage = () => {
    if (
      getReplyThisClientData?.response_time === "rush_audio" ||
      getReplyThisClientData?.response_time === "audio"
    ) {
      dispatch(setIsSendingMessageIsSuccessfully(true));
      handleAudioSendMessage();
    } else if (isReEngagementClicked) {
      handleReEngagementSendMessagetoClient();
    } else {
      if (
        (isReplyThisClient || chatHeadSelectedUser) &&
        session_status !== "S"
      ) {
        dispatch(setIsSendingMessageIsSuccessfully(true));
      }
      handleSendMessagetoClient();
      dispatch(setAdvisorMessage(""));
    }
  };

  const handleAudioSendMessage = async () => {
    setIsAudioLoading(true);
    if (!audioURL) {
      console.error("No audio URL available for upload.");
      return;
    }
    const formattedDate = formatDateForFilename(new Date());
    // Fetch the Blob from the audioURL
    const response = await fetch(audioURL);
    const audioBlob = await response.blob();

    // Create an audio element to get the duration
    const audioElement = document.createElement("audio");
    audioElement.src = URL.createObjectURL(audioBlob);

    // Wait for the metadata to be loaded
    await new Promise((resolve) => {
      audioElement.addEventListener("loadedmetadata", resolve);
    });

    // Get the duration and format it
    const duration = Math.floor(audioElement.duration); // Duration in seconds
    const name = `${getReplyThisClientData?.sender_id}-to-${userId}-${formattedDate}~${duration}.mp3`;

    const params = {
      Bucket: "mytarotadvisorvideos", // Replace with your bucket name
      Key: name,
      Body: audioBlob, // Use the Blob instead of the URL
      ContentType: "audio/mp3",
      ACL: "public-read", // Adjust as needed
    };

    s3.upload(params, (err, data) => {
      if (err) {
        // Handle the error
        console.error("Upload Error:", err);
        setIsAudioLoading(false);
        return;
      }

      if (data.Location) {
        setIsRecording(false);
        setAudioURL("");
        audioChunksRef.current = [];
        localStorage.setItem("audioUrl", data.Location);
        handleSendMessagetoClient();
        setIsAudioLoading(false);
      }
    });
  };

  const handleReEngagementSendMessagetoClient = async () => {
    if (advisorReEngagementMessage.trim().length < 250) {
      dispatch(setIsSendingMessageIsSuccessfully(false));
      toast.custom((t) => (
        <div
          className={`${
            t.visible ? "animate-enter" : "animate-leave"
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">
              <div className="ml-3 flex-1">
                <p className="text-sm font-medium text-gray-900">
                  Text Response Message Should Be At Least
                </p>
                <p className="mt-1 text-sm text-gray-500">
                  250 Characters Long
                </p>
              </div>
            </div>
          </div>
        </div>
      ));
      return;
    }
    setIsReEngagementLoading(true);
    const clientName =
      chatHeadSelectedUser?.sender_id == userId
        ? chatHeadSelectedUser?.receiver_displayname
        : chatHeadSelectedUser?.sender_displayname;
    const clientid =
      chatHeadSelectedUser?.sender_id == userId
        ? chatHeadSelectedUser?.receiver_id
        : chatHeadSelectedUser?.sender_id;

    const formData = sendFormData(
      chatHeadSelectedUser?.message_type,
      advisorReEngagementMessage,
      id,
      username,
      clientid,
      clientName,
      chatHeadSelectedUser?.message_review_id,
      chatHeadSelectedUser?.msg_date
    );

    try {
      const result = await saveChatPayload(formData);

      if (result?.data?.result == "1") {
        dispatch(setAdvisorReEngagementMessage(""));
        dispatch(setIsReEngagementRepliedSuccessfully(true));
        setIsReEngagementLoading(false);
      }
    } catch (error) {}
  };

  const handleChatGptResponse = async () => {
    setIsReEngagementLoading(true);
    const clientid =
      chatHeadSelectedUser?.sender_id == userId
        ? chatHeadSelectedUser?.receiver_id
        : chatHeadSelectedUser?.sender_id;
    const formData = new FormData();
    formData.append("advisor_id", id);
    formData.append("client_id", clientid);
    formData.append("type", "re-engagement");
    formData.append("user_id", id);
    formData.append("user_type", "advisor");
    try {
      const result = await createMessage(formData);
      if (result?.data?.result == "1") {
        dispatch(setAdvisorReEngagementMessage(result?.data?.message));
        setIsReEngagementLoading(false);
      }
    } catch (error) {
      setIsReEngagementLoading(false);
    }
  };

  const isAudioResponse =
    getReplyThisClientData?.response_time === "audio" ||
    getReplyThisClientData?.response_time === "rush_audio";

  const filteredData = chatBodyLiveFilteredData.filter((item) => {
    return (
      item.status === "0" &&
      (item.message_type === "text" ||
        item.message_type === "rush_text" ||
        item.message_type === "photo" ||
        item.message_type === "rush_photo") &&
      item?.sender_type === "client"
    );
  });

  // Get the first object from the filtered results
  const firstZeroStatusData = filteredData.length > 0 ? filteredData[0] : null;

  const isDisabled =
    firstZeroStatusData === null
      ? false
      : Object.values(firstZeroStatusData).length > 0 &&
        Object.values(getReplyThisClientData).length > 0
      ? false
      : true;

  return (
    <div className="flex-grow h-full flex flex-col ">
      <div className=" flex items-center justify-between w-full h-15 p-1 shadow-md rounded-lg  bg-white px-4">
        <div className="flex align-middle items-center">
          <div className="border rounded-full border-white p-1/2">
            <div
              className={`w-10 h-10 m-1 text-2xl flex justify-center items-center rounded-full text-white shadow-md ${colorClass}`}
            >
              {session_status === "A" ||
              session_status === "S" ||
              session_status === "X"
                ? getFirstLetter(client_displayname)
                : getFirstLetter(
                    getClientName(
                      chatHeadSelectedUser?.sender_id,
                      chatHeadSelectedUser?.receiver_displayname,
                      chatHeadSelectedUser?.sender_displayname
                    )
                  )}
            </div>
          </div>
          <div className="flex-grow p-2">
            <div className="text-md text-gray-700 font-semibold capitalize">
              {session_status === "A" ||
              session_status === "S" ||
              session_status === "X"
                ? client_displayname
                : getClientName(
                    chatHeadSelectedUser?.sender_id,
                    chatHeadSelectedUser?.receiver_displayname,
                    chatHeadSelectedUser?.sender_displayname
                  )}
            </div>
          </div>
        </div>

        {(session_status === "S" || session_status === "X") && (
          <>
            <div className="flex flex-col items-center">
              <h1 className="flex gap-2 items-center mb-1">
                <span className="text-sm font-medium ">Duration :</span>
                <Timer />
              </h1>
              <h1 className="flex gap-4 items-center text-xs bg-[#3A86F4] text-white py-1 px-3 rounded-md">
                {`${freeTimer || 0} promo seconds + ${
                  paidTimer || 0
                } paid seconds`}
              </h1>
            </div>
            <button
              className="bg-red-600 text-white p-2 px-3 text-base font-medium rounded-md"
              onClick={handleChatResponse}
            >
              End Chat
            </button>
          </>
        )}
        <div
          className={`items-center gap-4 ${
            session_status === "S" ? "hidden" : "flex"
          }`}
        >
          {isInfoMessageToShow &&
            getReplyThisClientData &&
            chatHeadSelectedUser?.message_type !== "advisor_com" && (
              <button
                className="bg-red-500 text-white text-base px-4 py-1 rounded-lg"
                onClick={() => dispatch(setIsInfoModalOpen(true))}
              >
                Info
              </button>
            )}
          {session_status !== "S" && <AdvisorStatus />}
        </div>
      </div>

      {isMessageLoading || isRenderedMessageLoading ? (
        <div className="flex justify-center items-center h-[80%]">
          <Loader width={"50px"} height={"50px"} color={"#3A86F4"} />
        </div>
      ) : (
        <motion.div className="w-full flex-grow bg-gray-100 my-2 p-2 overflow-y-auto max-h-full relative">
          {!noMoreDataToShow && (
            <div
              className={`absolute text-white bg-[#3A86F4] left-4 cursor-pointer shadow-lg text-xs py-2 px-2 transition ease-in-out ${
                isLoadMoreMessageLoading ? "rounded-full" : "rounded-3xl"
              }`}
              onClick={handleLoadMoreMessages}
            >
              {isLoadMoreMessageLoading ? (
                <Loader width={"30px"} height={"30px"} color={"#fff"} />
              ) : (
                "Load More"
              )}
            </div>
          )}
          {noMoreDataToShow && (
            <div class="flex items-center my-2 w-full m-auto">
              <div className="flex-grow border-t border-[#595F62] opacity-30"></div>
              <span className="px-4 text-black text-base font-medium opacity-90">
                No More Messages{" "}
              </span>
              <div className="flex-grow border-t border-[#595F62] opacity-30"></div>
            </div>
          )}
          {[...(chatBodyLiveFilteredData || [])]
            ?.sort(sortMessages)
            .map((message) => (
              <ChatMessage
                key={message?.id}
                message={message}
                userSessionData={userSessionData}
              />
            ))}
          {[...chatBodyLiveFirebaseData]?.sort(sortMessages).map((message) => (
            <ChatMessage
              key={message?.id}
              message={message}
              userSessionData={userSessionData}
            />
          ))}

          <div ref={messageContainerRef}></div>
          {session_status === "S" && (
            <button
              className="fixed bottom-20 right-6 w-auto bg-white p-2 rounded-lg shadow-lg"
              onClick={() => dispatch(setIsLiveChatAIResponseOpen(true))}
            >
              <BiMessageDetail className="text-[22px]" />
            </button>
          )}
        </motion.div>
      )}
      <div className="relative">
        {isEmojiOpen && (
          <div className="absolute bottom-16 left-4">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
            >
              <AiOutlineCloseCircle
                onClick={() => setIsEmojiOpen(false)}
                style={{ fontSize: "20px", color: "#667085" }}
              />
            </div>
            <EmojiPicker
              onEmojiClick={addEmoji}
              searchDisabled={true}
              size="30"
              height={400}
              width={300}
            />
          </div>
        )}
        <div className="px-3 rounded-3xl bg-white shadow-lg relative">
          <div className="flex items-center">
            {audioURL && (
              <div className="flex items-center gap-4 p-3">
                <button onClick={deleteRecording}>
                  <MdDelete className="text-[22px] text-red-500" />
                </button>
                <audio
                  ref={audioRef}
                  controls
                  className="h-[30px]"
                  preload="metadata"
                >
                  <source src={audioURL} />
                </audio>
              </div>
            )}
            {!isRecording &&
              !audioURL &&
              (!isAudioResponse ? (
                <div className="flex gap-1">
                  <button
                    className="cursor-pointer"
                    onClick={() => setIsEmojiOpen(true)}
                    disabled={isDisabled}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </button>

                  <button
                    className="cursor-pointer"
                    onClick={() => dispatch(setIsTemplateModalOpen(true))}
                    disabled={isDisabled}
                  >
                    <IoNewspaperOutline className="text-[22px]" />
                  </button>

                  {session_status === "S" && (
                    <button
                      className="cursor-pointer"
                      onClick={() => dispatch(setIsPictureModalOpen(true))}
                    >
                      <RiImageAddLine className="text-[22px]" />
                    </button>
                  )}
                </div>
              ) : (
                ""
              ))}
            <div className="search-chat flex flex-grow p-2">
              {isRecording || audioURL ? (
                !audioURL && (
                  <div className="flex-grow flex items-center gap-4">
                    <button
                      onClick={stopRecording}
                      className="w-3 h-3 bg-red-600"
                    ></button>
                    <OrderTimer
                      remainingTime={timerStates["recordingAudio"] || 0}
                    />
                  </div>
                )
              ) : session_status !== "S" &&
                !isAudioResponse &&
                isInboxIsClicked ? (
                <textarea
                  className="input text-sm focus:outline-none bg-white flex-grow rounded-l-md p-2 resize-none overflow-y-hidden "
                  placeholder="Type your message ..."
                  value={advisorTxtOrderMessage}
                  onChange={(e) => {
                    dispatch(setAdvisorTxtOrderMessage(e.target.value));
                  }}
                  onKeyDown={(e) => handleKeyDown(e)}
                  ref={textareaRef}
                  disabled={isDisabled}
                  rows={1}
                  style={{ overflow: "hidden" }}
                />
              ) : isReEngagementClicked ? (
                <textarea
                  className="input text-sm focus:outline-none bg-white flex-grow rounded-l-md p-2 resize-none overflow-y-hidden "
                  placeholder="Type your message ..."
                  value={advisorReEngagementMessage}
                  onChange={(e) => {
                    dispatch(setAdvisorReEngagementMessage(e.target.value));
                  }}
                  onKeyDown={(e) => handleKeyDown(e)}
                  ref={textareaRef}
                  rows={1}
                  style={{ overflow: "hidden" }}
                />
              ) : (
                !isAudioResponse && (
                  <textarea
                    className="input text-sm focus:outline-none bg-white flex-grow rounded-l-md p-2 resize-none overflow-y-hidden "
                    placeholder="Type your message ..."
                    value={advisorMessage}
                    onChange={(e) => {
                      dispatch(setAdvisorMessage(e.target.value));
                    }}
                    ref={textareaRef}
                    onKeyDown={(e) => handleKeyDown(e)}
                    disabled={session_status !== "S"}
                    rows={1}
                    style={{ overflow: "hidden" }}
                  />
                )
              )}
            </div>
            {!audioURL && isAudioResponse && (
              <motion.button
                onClick={isRecording ? stopRecording : startRecording}
                className="p-2"
              >
                <motion.div
                  variants={pulseAnimation}
                  initial="initial"
                  animate={isRecording ? "animate" : "initial"}
                >
                  <IoMicSharp
                    className={`text-[22px] ${
                      isRecording ? "text-red-500" : "text-gray-500"
                    }`}
                  />
                </motion.div>
              </motion.button>
            )}
            {isReEngagementLoading ? (
              <Loader width={"30px"} height={"30px"} color={"#3A86F4"} />
            ) : (
              <div className="flex items-center gap-2">
                {isReEngagementClicked && (
                  <button onClick={handleChatGptResponse}>
                    <img src={AIIcon} alt="AI Icon" />
                  </button>
                )}
                {(isAudioResponse && !audioURL) ||
                  (!isRecording && (
                    <button
                      className={`${
                        isInboxIsClicked && isDisabled
                          ? "bg-gray-600"
                          : "bg-[#3A86F4]"
                      } flex justify-center items-center p-2 rounded-full `}
                      onClick={handleSendMessage}
                      disabled={
                        isAudioLoading || (isInboxIsClicked && isDisabled)
                      }
                    >
                      {isAudioLoading || isSendingMessageIsSuccessfully ? (
                        <Loader width={30} height={30} color={"#fff"} />
                      ) : (
                        <BiSolidSend className={`text-2xl text-white`} />
                      )}
                    </button>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;
