import { userApi } from "../userApi";

export const extendedApiSlice = userApi.injectEndpoints({
  endpoints: (builder) => ({
    advisorLogin: builder.mutation({
      query: (formData) => ({
        url: "Client_API/login",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Login"],
    }),
  }),
});

export const { useAdvisorLoginMutation } = extendedApiSlice;
