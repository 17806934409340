// slices/chatSlice.js
import { createSlice } from "@reduxjs/toolkit";
import getUserId from "../../utils/helper/getUserId";
const userId = getUserId("");
const chatSlice = createSlice({
  name: "chat",
  initialState: {
    chatHeadLiveFilteredData: [],
    chatHeadReEngagementData: [],
    chatBodyLiveFilteredData: [],
    chatBodyLiveFirebaseData: [],
    chatHeadSelectedUser: {},
    loadMoreMessages: 0,
    isLoadMoreMessageLoading: false,
    noMoreDataToShow: false,
    isMessageLoading: false,
    userSessionData: {},
    userChatsLog: {},
    advisorMessage: "",
    advisorTxtOrderMessage: "",
    advisorReEngagementMessage: "",
    firebaseClientData: {},
    sessionTimer: 0,
    freeTimer: 0,
    paidTimer: 0,
    isLiveChatExtended: false,
    liveChatExtendedData: {},
    liveChatImageUrl: null,
    liveChatImage: null,
    getPubnubMessages: null,
    isReplyThisClient: false,
    getReplyThisClientData: {},
    isMessageRepliedSuccessfully: false,
    isInfoMessageToShow: false,
    isAdminStatusOk: false,
    getPubnubMessageInfo: {},
    getTextTemplate: [],
    getLiveTemplate: [],
    getFollowupTemplate: [],
    isInboxIsClicked: true,
    isReEngagementClicked: false,
    isReEngagementRepliedSuccessfully: false,
    isFollowUpMessageTrue: false,
    chatHeadPagination: 0,
    chatHeadPaginationAdding: 0,
    isTyingClient: false,
    isSendingMessageIsSuccessfully: false,
  },
  reducers: {
    setGetPubnubMessages: (state, action) => {
      state.getPubnubMessages = action.payload;
      const newMessage = action.payload;

      if (newMessage && newMessage.sender_id) {
        const senderId = newMessage.sender_id;
        const messageExists = state.chatBodyLiveFilteredData.some(
          (msg) => msg.sender_id === senderId
        );
        if (messageExists) {
          state.chatBodyLiveFilteredData = [
            ...state.chatBodyLiveFilteredData,
            newMessage,
          ];
        }

        // Check if the selected user's sender_id matches the message's sender_id
        // and if the message status is "0"
        if (
          state.chatHeadSelectedUser?.sender_id === newMessage.sender_id &&
          newMessage?.status === "0"
        ) {
          state.isInfoMessageToShow = true; // Directly update the state
        }
      }
    },
    setChatBodyLiveFilteredData: (state, action) => {
      if (!Array.isArray(action.payload)) {
        console.error("Expected an array but received:", action.payload);
        return;
      }
      if (state.loadMoreMessages === 0) {
        state.chatBodyLiveFilteredData = action.payload;
      } else {
        state.chatBodyLiveFilteredData = [
          ...state.chatBodyLiveFilteredData,
          ...action.payload,
        ];
      }
    },

    setChatHeadLiveFilteredData: (state, action) => {
      state.chatHeadLiveFilteredData = [
        ...state.chatHeadLiveFilteredData,
        ...action.payload,
      ];
    },

    setChatHeadReEngagementData: (state, action) => {
      state.chatHeadReEngagementData = action.payload;
    },
    setLoadMoreMessages: (state, action) => {
      state.loadMoreMessages = action.payload;
    },
    setNoMoreDataToShow: (state, action) => {
      state.noMoreDataToShow = action.payload;
    },
    setChatHeadSelectedUser: (state, action) => {
      state.chatHeadSelectedUser = action.payload;
      state.loadMoreMessages = 0;
      state.noMoreDataToShow = false;
    },
    setIsMessageLoading: (state, action) => {
      state.isMessageLoading = action.payload;
    },
    setIsLoadMoreMessageLoading: (state, action) => {
      state.isLoadMoreMessageLoading = action.payload;
    },
    setUserSessionData: (state, action) => {
      state.userSessionData = action.payload;
    },
    setChatBodyLiveFirebaseData: (state, action) => {
      state.chatBodyLiveFirebaseData = action.payload;
    },
    setAdvisorMessage: (state, action) => {
      state.advisorMessage = action.payload;
    },
    setAdvisorTxtOrderMessage: (state, action) => {
      state.advisorTxtOrderMessage = action.payload;
    },
    setAdvisorReEngagementMessage: (state, action) => {
      state.advisorReEngagementMessage = action.payload;
    },
    addEmojiToMessage: (state, action) => {
      if (state.isInboxIsClicked && state.isReplyThisClient) {
        state.advisorTxtOrderMessage =
          state.advisorTxtOrderMessage + action.payload;
      } else if (state.isReEngagementClicked) {
        state.advisorReEngagementMessage =
          state.advisorReEngagementMessage + action.payload;
      } else {
        state.advisorMessage = state.advisorMessage + action.payload;
      }
    },
    setFirebaseClientData: (state, action) => {
      state.firebaseClientData = action.payload;
    },
    setSessionTimer: (state, action) => {
      state.sessionTimer = action.payload;
    },
    setFreeTimer: (state, action) => {
      state.freeTimer = action.payload;
    },
    setPaidTimer: (state, action) => {
      state.paidTimer = action.payload;
    },
    setIsLiveChatExtended: (state, action) => {
      state.isLiveChatExtended = action.payload;
    },
    setLiveChatExtendedData: (state, action) => {
      state.liveChatExtendedData = action.payload;
    },
    setLiveChatImageUrl: (state, action) => {
      state.liveChatImageUrl = action.payload;
    },
    setLiveChatImage: (state, action) => {
      state.liveChatImage = action.payload;
    },
    setUserChatsLog: (state, action) => {
      state.userChatsLog = action.payload;
    },
    setIsReplyThisClient: (state, action) => {
      state.isReplyThisClient = action.payload;
    },
    setGetReplyThisClientData: (state, action) => {
      state.getReplyThisClientData = action.payload;
    },
    setIsMessageRepliedSuccessfully: (state, action) => {
      state.isMessageRepliedSuccessfully = action.payload;
    },
    setIsInfoMessageToShow: (state, action) => {
      state.isInfoMessageToShow = action.payload;
    },
    setGetTextTemplate: (state, action) => {
      state.getTextTemplate = action.payload;
    },
    setGetLiveTemplate: (state, action) => {
      state.getLiveTemplate = action.payload;
    },
    setGetFollowupTemplate: (state, action) => {
      state.getFollowupTemplate = action.payload;
    },
    setIsAdminStatusOk: (state, action) => {
      state.isAdminStatusOk = action.payload;
    },

    setIsReEngagementClicked: (state, action) => {
      state.isReEngagementClicked = action.payload;
    },
    setIsInboxIsClicked: (state, action) => {
      state.isInboxIsClicked = action.payload;
    },
    setIsFollowUpMessageTrue: (state, action) => {
      state.isFollowUpMessageTrue = action.payload;
    },
    setIsReEngagementRepliedSuccessfully: (state, action) => {
      state.isReEngagementRepliedSuccessfully = action.payload;
    },
    setChatHeadPagination: (state, action) => {
      state.chatHeadPagination = action.payload;
    },
    setChatHeadPaginationAdding: (state, action) => {
      state.chatHeadPagination += action.payload;
    },
    setIsTyingClient: (state, action) => {
      state.isTyingClient = action.payload;
    },
    setIsSendingMessageIsSuccessfully: (state, action) => {
      state.isSendingMessageIsSuccessfully = action.payload;
    },
    setGetPubnubMessageInfo: (state, action) => {
      state.getPubnubMessageInfo = action.payload;
      const newMessage = action.payload;

      if (newMessage && newMessage.sender_id) {
        const senderId = newMessage.sender_id;
        const messageExists = state.chatBodyLiveFilteredData.some(
          (msg) => msg.sender_id === senderId
        );
        if (messageExists) {
          state.chatBodyLiveFilteredData = [
            ...state.chatBodyLiveFilteredData,
            newMessage,
          ];
        }
      }
    },
  },
});

export const {
  setChatHeadSelectedUser,
  setChatHeadLiveFilteredData,
  setChatBodyLiveFilteredData,
  setLoadMoreMessages,
  setNoMoreDataToShow,
  setIsMessageLoading,
  setIsLoadMoreMessageLoading,
  setUserSessionData,
  setChatBodyLiveFirebaseData,
  setAdvisorMessage,
  setFirebaseClientData,
  setSessionTimer,
  setFreeTimer,
  setPaidTimer,
  setIsLiveChatExtended,
  setLiveChatExtendedData,
  addEmojiToMessage,
  setLiveChatImageUrl,
  setLiveChatImage,
  setUserChatsLog,
  setGetPubnubMessages,
  setIsReplyThisClient,
  setGetReplyThisClientData,
  setAdvisorTxtOrderMessage,
  setIsMessageRepliedSuccessfully,
  setIsInfoMessageToShow,
  setGetPubnubMessageInfo,
  setGetLiveTemplate,
  setGetTextTemplate,
  setGetFollowupTemplate,
  setIsAdminStatusOk,
  setIsInboxIsClicked,
  setIsReEngagementClicked,
  setChatHeadReEngagementData,
  setAdvisorReEngagementMessage,
  setIsReEngagementRepliedSuccessfully,
  setIsFollowUpMessageTrue,
  setChatHeadPagination,
  setChatHeadPaginationAdding,
  setIsSendingMessageIsSuccessfully,
  setIsTyingClient,
} = chatSlice.actions;

export default chatSlice.reducer;
