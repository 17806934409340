import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { prepareFormData } from "../../utils/helper/prepareFormData";
import { setIsInfoModalOpen } from "./modalSlice";
import useGetAdvisorInfo from "../../hooks/useGetAdvisorInfo";
import { useSaveChatPayloadMutation } from "../../app/api/chat";
import { setIsMessageRepliedSuccessfully } from "../chat/chatSlice";
import Loader from "../../components/Loader";
import useLogout from "../../hooks/useLogout";
import toast from "react-hot-toast";

const InfoModal = () => {
  const dispatch = useDispatch();
  const [infoMessage, setInfoMessage] = useState("");
  const [isInfoMessageLoading, setIsInfoMessageLoading] = useState(false);
  const [saveChatPayload, { error }] = useSaveChatPayloadMutation();
  const { isInfoModalOpen } = useSelector((state) => state.modal);
  const { chatHeadSelectedUser, chatBodyLiveFilteredData } = useSelector(
    (state) => state.chat
  );
  const { handleLogout } = useLogout();
  const { advisorInfoData } = useGetAdvisorInfo();
  const { id = "", username = "" } = advisorInfoData?.user || {};

  if (error) {
    if (error.status === 401) {
      handleLogout();
    }
  }

  const closeModal = () => {
    dispatch(setIsInfoModalOpen(false));
  };
  const { sender_id, sender_displayname, receiver_displayname, receiver_id } =
    chatHeadSelectedUser;

  const sendInfoMessage = async () => {
    if (infoMessage.trim().length == 0) {
      toast("Please enter a message.", {
        duration: 2000,
      });
      return;
    }
    setIsInfoMessageLoading(true);
    const filteredData = chatBodyLiveFilteredData.filter(
      (item) =>
        item.status === "0" &&
        (item.message_type === "text" || item.message_type === "rush_text") &&
        item?.sender_type === "client"
    );

    // Get the first object from the filtered results
    const firstZeroStatusData =
      filteredData.length > 0 ? filteredData[0] : null;
    const { message_review_id, msg_date } = firstZeroStatusData;

    const clientName =
      sender_id === id ? receiver_displayname : sender_displayname;
    const clientid = sender_id === id ? receiver_id : sender_id;
    const formData = prepareFormData(
      "info",
      infoMessage,
      id,
      username,
      clientid,
      clientName,
      message_review_id,
      msg_date
    );

    try {
      const result = await saveChatPayload(formData);
      if (result?.data?.result == "1") {
        setInfoMessage("");
        closeModal();
        setIsInfoMessageLoading(false);
        dispatch(setIsMessageRepliedSuccessfully(true));
      }
    } catch (error) {}
  };
  return (
    <AnimatePresence>
      {isInfoModalOpen && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[99999999999] backdrop-blur-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="flex flex-col bg-white min-w-80 w-fit py-4 px-12 relative rounded-lg"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div>
              <div className="text-lg capitalize">
                <h6 className="text-base text-gray-700 mb-2">Info Message</h6>
                <textarea
                  type="text"
                  placeholder="Type your info message"
                  className="border-black border-solid border-[1px] resize-none outline-none rounded-lg shadow-lg p-2"
                  rows={5}
                  cols={40}
                  onChange={(e) => setInfoMessage(e.target.value)}
                  value={infoMessage}
                />
              </div>
              <div className="flex items-center justify-between mt-2">
                <button
                  className="py-2 px-6 bg-[#3A86F4] text-white rounded-lg shadow-lg"
                  onClick={sendInfoMessage}
                  disabled={isInfoMessageLoading}
                >
                  {isInfoMessageLoading ? (
                    <Loader width={30} height={30} color={"#fff"} />
                  ) : (
                    "Send"
                  )}
                </button>
                {!isInfoMessageLoading && (
                  <button
                    className="py-2 px-6 bg-red-500 text-white rounded-lg shadow-lg"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default InfoModal;
