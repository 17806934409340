import { userApi } from "../userApi";

export const extendedApiSlice = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdvisorChatHead: builder.mutation({
      query: (formData) => ({
        url: "Message_API/getChatHead_web",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Chat"],
    }),
    getChatHeadReEngagement: builder.mutation({
      query: (formData) => ({
        url: "Message_API/getChatHeadReEngagement",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Chat"],
    }),
    getAdvisorChatBody: builder.mutation({
      query: (formData) => ({
        url: "Message_API/getChatBody_v3",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Chat"],
    }),
    updateChatRoom: builder.mutation({
      query: (formData) => ({
        url: "Live_Session_API/updateChatRoom",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Chat"],
    }),
    saveChatPayload: builder.mutation({
      query: (formData) => ({
        url: "Pubnub/saveChatPayload",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Chat"],
    }),
    updateStatusByMulipleReviewIds: builder.mutation({
      query: (formData) => ({
        url: "Message_API/update_status_by_multiple_review_ids",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Chat"],
    }),
    createMessage: builder.mutation({
      query: (formData) => ({
        url: "ChatGPT_API/getAIResponse",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Chat"],
    }),
  }),
});

export const {
  useGetAdvisorChatHeadMutation,
  useGetAdvisorChatBodyMutation,
  useUpdateChatRoomMutation,
  useSaveChatPayloadMutation,
  useUpdateStatusByMulipleReviewIdsMutation,
  useGetChatHeadReEngagementMutation,
  useCreateMessageMutation,
} = extendedApiSlice;
