import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFreeTimer,
  setPaidTimer,
  setSessionTimer,
} from "../features/chat/chatSlice";
import {
  setClientResponseFromUpdateChatRoom,
  setIsWaitingForClientRecharge,
  setIsWaitingForMessageOpen,
} from "../features/modal/modalSlice";
import useAllModalTimerHandler from "./useAllModalTimerHandler";
import { generateDefaultFormData } from "../utils/helper/getUpdateRoomData";
import { useUpdateChatRoomMutation } from "../app/api/chat";

const createWorker = (workerScript) => {
  const blob = new Blob([workerScript], { type: "application/javascript" });
  const url = URL.createObjectURL(blob);
  return new Worker(url);
};

const useHandleTimer = () => {
  const { startAllModalHandlerTimer } = useAllModalTimerHandler();
  const [updateChatRoom] = useUpdateChatRoomMutation();
  const { advisorLoginResponse } = useSelector((state) => state.login);
  const { userSessionData } = useSelector((state) => state.chat);
  const extendedCreditWorkerRef = useRef(null);
  const freeSecondsWorkerRef = useRef(null);
  const paidSecondsWorkerRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const timerWorkerScript = (timerType) => `
      let liveSeconds;
      let isRunning = false;
      self.onmessage = function(e) {
        if (typeof e.data === 'number') {
          liveSeconds = e.data;
        } else if (e.data === 'start' && !isRunning) {
          isRunning = true;
          if (typeof liveSeconds !== 'undefined') {
            self.interval = setInterval(() => {
              liveSeconds -= 1;
              self.postMessage({ type: '${timerType}', value: liveSeconds });
              if (liveSeconds === 0) {
                self.postMessage({ type: '${timerType}', value: '0' });
                clearInterval(self.interval);
                isRunning = false;
              }
            }, 1000);
          }
        } else if (e.data === 'stop') {
          clearInterval(self.interval);
          isRunning = false;
        }
      };
    `;

    extendedCreditWorkerRef.current = createWorker(
      timerWorkerScript("session")
    );
    freeSecondsWorkerRef.current = createWorker(timerWorkerScript("free"));
    paidSecondsWorkerRef.current = createWorker(timerWorkerScript("paid"));

    const handleWorkerMessage = (e) => {
      const { type, value } = e.data;
      if (type === "session") {
        if (value === "done" || value === 0) {
          stopTimerForChat();
          dispatch(setSessionTimer(0));
          dispatch(setFreeTimer(0));
          dispatch(setPaidTimer(0));
          dispatch(
            setClientResponseFromUpdateChatRoom(
              "waiting for client to refill his live chat"
            )
          );
          dispatch(setIsWaitingForClientRecharge(true));
          startAllModalHandlerTimer(
            "waitingForCreditsToRefil",
            120,
            async () => {
              dispatch(setIsWaitingForClientRecharge(false));
              const formData = generateDefaultFormData(
                userSessionData?.session_id,
                "live_chat_end",
                advisorLoginResponse?.user?.id
              );
              const response = await updateChatRoom(formData);
            }
          );
        } else {
          dispatch(setSessionTimer(value));
        }
      }
      if (type === "paid") dispatch(setPaidTimer(value));
      if (type === "free") {
        if (value === "0") {
          paidSecondsWorkerRef.current.postMessage("start");
        } else {
          dispatch(setFreeTimer(value));
        }
      }
    };

    extendedCreditWorkerRef.current.onmessage = handleWorkerMessage;
    freeSecondsWorkerRef.current.onmessage = handleWorkerMessage;
    paidSecondsWorkerRef.current.onmessage = handleWorkerMessage;

    return () => {
      extendedCreditWorkerRef.current.terminate();
      freeSecondsWorkerRef.current.terminate();
      paidSecondsWorkerRef.current.terminate();
    };
  }, [dispatch, userSessionData]);

  const startTimerForChat = (totalSeconds, freeSeconds, paidSeconds) => {
    if (typeof totalSeconds === "number" && !isNaN(totalSeconds)) {
      extendedCreditWorkerRef.current.postMessage(totalSeconds);
      extendedCreditWorkerRef.current.postMessage("start");

      paidSecondsWorkerRef.current.postMessage(paidSeconds);

      freeSecondsWorkerRef.current.postMessage(freeSeconds);
      if (freeSeconds !== 0) {
        freeSecondsWorkerRef.current.postMessage("start");
      } else {
        paidSecondsWorkerRef.current.postMessage("start");
      }

      dispatch(setSessionTimer(totalSeconds));
      dispatch(setFreeTimer(freeSeconds));
      dispatch(setPaidTimer(paidSeconds));
    } else {
      console.error("Invalid initial value for totalSeconds:", totalSeconds);
    }
  };

  const stopTimerForChat = () => {
    extendedCreditWorkerRef.current.postMessage("stop");
    freeSecondsWorkerRef.current.postMessage("stop");
    paidSecondsWorkerRef.current.postMessage("stop");
  };

  return { startTimerForChat, stopTimerForChat };
};

export default useHandleTimer;
