import React from "react";
import Login from "./features/login/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import Chat from "./components/Chat";
import CallingModal from "./features/modal/CallingModal";
import ClientResponseModal from "./features/modal/ClientResponseModal";
import WaitingForMessage from "./features/modal/WaitingForMessage";
import WaitingForRecharge from "./features/modal/WatingForRecharge";
import LiveChatEnded from "./features/modal/LiveChatEnded";
import PictureModal from "./features/modal/PictureModal";
import ErrorHandlingModal from "./features/modal/ErrorHandlingModal";
import InfoModal from "./features/modal/InfoModal";
import TemplateModal from "./features/modal/TemplateModal";
import InsertTemplateModal from "./features/modal/InsertTemplateModal";
import AcknowledgeModal from "./features/modal/AcknowledgeModal";
import AcknowledgedModal from "./features/modal/AcknowledgedModal";
import RefundModal from "./features/modal/RefundModal";
import LiveChatSummaryAIModal from "./features/modal/LiveChatSummaryAIModal";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Chat />
              <CallingModal />
              <ClientResponseModal />
              <WaitingForMessage />
              <WaitingForRecharge />
              <LiveChatEnded />
              <PictureModal />
              <ErrorHandlingModal />
              <InfoModal />
              <TemplateModal />
              <InsertTemplateModal />
              <AcknowledgeModal />
              <AcknowledgedModal />
              <RefundModal />
              <LiveChatSummaryAIModal />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
