import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { store } from "./app/store";
import { Provider } from "react-redux";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
const pubnub = new PubNub({
  publishKey: process.env.REACT_APP_PUBLISHKEYLIVE,
  subscribeKey: process.env.REACT_APP_SUBSCRIBEKEYLIVE,
  chatChannel: "chat",
  applicationChannel: "channel_application",
  uuid: "MyUserID",
  restore: true,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PubNubProvider client={pubnub}>
    <Provider store={store}>
      <App />
    </Provider>
  </PubNubProvider>
);
