import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setAdvisorEmail,
  setAdvisorJWTToken,
  setAdvisorLoginResponse,
  setAdvisorPassword,
} from "./loginSlice";
import { useAdvisorLoginMutation } from "../../app/api/login";
import logo from "../../assets/Logo_Icon.svg";
const makeTarotID = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return "web_" + result;
};

const Login = () => {
  const dispatch = useDispatch();
  const [loginResponseError, setLoginResponseError] = useState();
  const { email, password } = useSelector((state) => state.login);
  const navigate = useNavigate();
  const [advisorLogin, { isLoading }] = useAdvisorLoginMutation();

  const handleLoginAdvisor = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("tarot_id", makeTarotID(20));
    formData.append("device_type", "web");

    try {
      const result = await advisorLogin(formData).unwrap();
      dispatch(setAdvisorLoginResponse(result));
      dispatch(setAdvisorJWTToken(result?.jwt));
      localStorage.setItem("advisorsData", JSON.stringify(result));
      if (result?.result === 1) {
        navigate("/");
      } else {
        setLoginResponseError(result?.message);
      }
    } catch (error) {}
  };
  return (
    <div className="flex flex-col justify-center m-auto w-11/12 max-w-[700px] px-10 py-20   h-screen">
      <h1 className="text-5xl font-semibold">
        <div className="flex items-center gap-3">
          <div>
            <img src={logo} alt="Logo Image Home Page" className="w-12" />
          </div>
          <h5 className={`font-bold text-gray-800  text-2xl`}>Psychic Txt</h5>
        </div>
      </h1>
      <p className="font-medium text-gray-500 mt-4">
        Welcome back! Please enter you details.
      </p>
      <form className="mt-8" onSubmit={handleLoginAdvisor}>
        <div className="flex flex-col">
          <label className="text-lg font-medium">Email</label>
          <input
            className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => {
              dispatch(setAdvisorEmail(e.target.value));
              setLoginResponseError("");
            }}
          />
        </div>
        <div className="flex flex-col mt-4">
          <label className="text-lg font-medium">Password</label>
          <input
            className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent"
            placeholder="Enter your password"
            type={"password"}
            value={password}
            onChange={(e) => {
              dispatch(setAdvisorPassword(e.target.value));
              setLoginResponseError("");
            }}
          />
        </div>
        {loginResponseError && (
          <p className="text-sm text-red-500 font-normal p-2 pl-4">
            {loginResponseError}
          </p>
        )}
        <div className="mt-8 flex justify-end items-center">
          <button className="font-medium text-base text-[#3A86F4]">
            Forgot password?
          </button>
        </div>
        <div className="mt-8 flex flex-col gap-y-4">
          <button
            type="submit"
            className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01]  ease-in-out transform py-4 bg-[#3A86F4] rounded-xl text-white font-bold text-lg"
          >
            {isLoading ? "Loading..." : "Sign in"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
