import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { userApi } from "./userApi";
import loginReducer, { setIsAuthenticated } from "../features/login/loginSlice";
import chatReducer from "../features/chat/chatSlice";
import modalReducer from "../features/modal/modalSlice";
import advisorReducer from "../features/advisor/advisorSlice";
// import { extendedApiSlice } from "../app/api/login";

export const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    login: loginReducer,
    chat: chatReducer,
    modal: modalReducer,
    advisor: advisorReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(userApi.middleware),
});

setupListeners(store.dispatch);
