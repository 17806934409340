import { useCallback } from "react";
import { useUpdateAdvisorLiveStatusMutation } from "../app/api/advisor";

export const useAdvisorStatus = (advisorId) => {
  const [updateAdvisorLiveStatus] = useUpdateAdvisorLiveStatusMutation();

  const setOfflineStatus = useCallback(async () => {
    try {
      const statusData = {
        id: advisorId,
        live_status: 0,
        device_type: "web",
        user_id: advisorId,
        user_type: "advisor",
      };

      const formData = new FormData();
      Object.entries(statusData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      await updateAdvisorLiveStatus(formData).unwrap();
      return { success: true };
    } catch (error) {
      console.error("Failed to update advisor status:", error);
      return { success: false, error };
    }
  }, [advisorId, updateAdvisorLiveStatus]);

  return { setOfflineStatus };
};
